import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import cn from 'classnames';

import * as entities from '../../../../../common/constants/entities';
import commonCoreMessages from '../../../messages/common.core.messages';
import widgetFiltersMessages from '../../../messages/widgets.filters.messages';
import List from './list/List';
import Input from './input/Input';

const translationsByFiltersType = {
  [entities.MACHINES]: {
    title: commonCoreMessages.machines,
    selectedEntry: widgetFiltersMessages.selectedMachines,
  },
  [entities.DATA_POINTS]: {
    title: commonCoreMessages.dataPoints,
    selectedEntry: widgetFiltersMessages.selectedDataPoints,
  },
};

export default class CheckboxFilters extends Component {
  static propTypes = {
    filtersType: PropTypes.string.isRequired,
    list: PropTypes.array.isRequired,
    selectedList: PropTypes.array,
    setSelectedList: PropTypes.func.isRequired,
    search: PropTypes.func.isRequired,
    hasSearch: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    selectedList: [],
  };

  constructor(props) {
    super(props);

    this.state = { query: '' };

    this.openFullList = this.openFullList.bind(this);
    this.changeQuery = this.changeQuery.bind(this);
    this.handleQueryChangeEvent = this.handleQueryChangeEvent.bind(this);
    this.addSelectedEntry = this.addSelectedEntry.bind(this);
    this.removeSelectedEntry = this.removeSelectedEntry.bind(this);
    this.removeAllSelectedEntries = this.removeAllSelectedEntries.bind(this);
  }

  componentWillMount() {
    const { hasSearch, search, filtersType } = this.props;
    if (hasSearch) {
      search(filtersType, '');
    }
  }

  openFullList() {
    this.setState({ showFullList: true });
  }

  changeQuery(query) {
    this.setState({ query });
    this.props.search(this.props.filtersType, query);
  }

  handleQueryChangeEvent(event) {
    const value = event.target.value;
    this.changeQuery(value);
  }

  addSelectedEntry(item) {
    const { selectedList, setSelectedList } = this.props;
    setSelectedList([...selectedList, item]);
  }

  removeSelectedEntry(item) {
    const { selectedList, setSelectedList } = this.props;
    setSelectedList(selectedList.filter((listItem) => listItem.id !== item.id));
  }

  removeAllSelectedEntries() {
    this.props.setSelectedList([]);
  }

  render() {
    const {
      filtersType, list, selectedList, hasSearch,
    } = this.props;
    const { query } = this.state;
    const hasSelectedEntries = selectedList.length > 0;
    const messages = translationsByFiltersType[filtersType];

    return (
      <div className={cn('search-alls', { 'search-alls-sm': hasSearch })}>
        <div className="search-title">
          <FormattedMessage {...messages.title} />
        </div>
        <div className="search-block">
          { hasSearch && (
            <>
              <Input value={query} onChange={this.handleQueryChangeEvent} />

              <div className="search-alls search-alls-sm">
                <div className="search-title">
                  { query && query.length > 0 && (
                    <span className="search-alls-text">
                      <FormattedMessage
                        {...widgetFiltersMessages.searchResultsForQuery}
                        values={{ query }}
                      />
                    </span>
                  ) }
                </div>
                <List list={list} onEntryClick={this.addSelectedEntry} stringToHighlight={query} type={filtersType} />
              </div>
            </>
          ) }

          { hasSelectedEntries && (
            <div className={cn('search-alls', { 'search-alls-sm': hasSearch })}>
              <div className="search-title">
                <button className="btn-link" type="button" onClick={this.removeAllSelectedEntries}>
                  <i className="icon-close" />
                </button>
                <span className="search-alls-text">
                  <FormattedMessage {...messages.selectedEntry} />
                </span>
              </div>

              <List list={selectedList} type={filtersType} onEntryClick={this.removeSelectedEntry} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
