"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
export const customStyles = {
  control: (provided, state) => __spreadProps(__spreadValues({}, provided), {
    backgroundColor: "white",
    borderColor: state.isFocused ? "#00acb6" : "#ccc",
    boxShadow: state.isFocused ? "0 0 0 0 #00acb6" : void 0,
    "&:hover": {
      borderColor: "#00acb6",
      cursor: "pointer"
    }
  }),
  menu: (provided) => __spreadProps(__spreadValues({}, provided), {
    zIndex: 1e3,
    width: "auto",
    minWidth: "100%"
  }),
  menuList: (provided) => __spreadProps(__spreadValues({}, provided), {
    width: "400px",
    minWidth: "100%"
  }),
  option: (provided, state) => __spreadProps(__spreadValues({}, provided), {
    color: state.isSelected ? "red" : "black",
    overflowY: "hidden",
    fontSize: "14px",
    lineHeight: "18px",
    "&:hover": {
      backgroundColor: "#e8ebef",
      cursor: "pointer"
    }
  }),
  multiValue: (provided) => __spreadProps(__spreadValues({}, provided), {
    backgroundColor: "#e0f3ff"
  }),
  multiValueLabel: (provided) => __spreadProps(__spreadValues({}, provided), {
    color: "#0073e6"
  }),
  multiValueRemove: (provided) => __spreadProps(__spreadValues({}, provided), {
    color: "#0073e6",
    ":hover": {
      backgroundColor: "#0073e6",
      color: "white"
    }
  })
};
