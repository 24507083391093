import React from 'react';
import { FormattedMessage } from 'react-intl';
import { logout } from '../../../auth/keycloakClient';

const TopBarLogoutButton: React.FC = () => {
  const handleLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    logout({ redirectUri: window.location.origin });
  };

  return (
    <a href='/logout' onClick={handleLogout} className='topMenu--link'>
      <span className='icon icon-logout' />
      <FormattedMessage id='topBar.profile.logout' defaultMessage='Logout' />
    </a>
  );
};

export default TopBarLogoutButton;
