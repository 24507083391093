import React, { useEffect } from 'react';
import { RouteConfigComponentProps } from 'react-router-config';
import { onAnalyzerSessionEditorEnter, onAnalyzerSessionEditorLeave } from '../index/routeHandlers';
import { AnalyzerSessionEditorHeader } from './header/AnalyzerSessionEditorHeader';
import { useSelector } from 'react-redux';
import {
  getSession,
  getSessionLocation,
  sessionsError,
  sessionsLoading,
} from '../../redux/selectors/analyzer.selector';
import { Loader } from '../_tools';
import { ErrorPlaceholder } from '../inventory/list/ErrorPlaceholder';
import SessionActions from './session/SessionActions';
import { decodeId } from '../../redux/util';
import { PanelList } from './panel/PanelList';

interface AnalyzerProps extends RouteConfigComponentProps {}

const useSessionId = (match: any): number => {
  const { sessionId } = match.params as { sessionId: string };
  return decodeId(sessionId);
};

export const AnalyzerSessionEditorPage: React.FC<AnalyzerProps> = ({ match }) => {
  const sessionId = useSessionId(match);

  useEffect(() => {
    if (sessionId && match.isExact) {
      onAnalyzerSessionEditorEnter(sessionId);
    }

    return () => {
      onAnalyzerSessionEditorLeave();
    };
  }, [sessionId, match.isExact]);

  const isLoading = useSelector((state) => sessionsLoading(state, sessionId));
  const error = useSelector((state) => sessionsError(state, sessionId));
  const location = useSelector((state) => getSessionLocation(state, sessionId));
  const session = useSelector((state) => getSession(state, sessionId));

  return (
    <>
      <Loader show={isLoading} />
      {error && <ErrorPlaceholder />}
      {!error && session && (
        <>
          <div className='col--1'>
            <AnalyzerSessionEditorHeader location={location} />
          </div>
          <div className='col--1 location-page'>
            <div className='users'>
              <SessionActions session={session} />
              <div className='users-wrap'>
                <PanelList />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
