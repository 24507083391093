import { isUndefined } from 'lodash';
import React from 'react';
import { useIntl } from 'react-intl';
import ReactSelect from 'react-select';
import { Todo } from '../../../../../../common/types/common';
import labels from '../../../../messages/dynamicField.messages';
import { NewRadio } from '../../../_tools/formFields/radio/Radio';
import { DataPointFormField } from './DataPointFormField';
import { Action } from './arrayPage/ArrayFormPage';

export type Dimensions = number[][][];
export type Indices = number[][];

/**
 * Take the two-dimensional array and turn it into the ARRAY [] OF string
 * @param dim
 */
export const innerDimensionsToString = (dim: number[][]): string => {
  if (typeof dim[0] === 'number' || typeof dim[0] === 'string')
    return `ARRAY [${dim.join(',')}] OF`;
  const internalDimensions: string = dim
    .map((d): string => {
      const [min, max] = d;
      return `${min}..${max}`;
    })
    .join(',');
  return `ARRAY [${internalDimensions}] OF`;
};

/**
 * Takes the 3 dimensional array representation of array dimensions and converts it into the string representation
 */
export const dimensionsToString = (dimensions: Dimensions): string =>
  dimensions.map(innerDimensionsToString).join(' ');

export const indicesToString = (indices: Indices): string => {
  if (typeof indices[0] === 'number' || typeof indices[0] === 'string')
    return `[${indices.join(',')}]`;
  else
    return indices
      .map((firstLayer): string => {
        const internalIndices: string = firstLayer.join(',');
        return `[${internalIndices}]`;
      })
      .join('');
};

/**
 * recursively check if the dimensions definition is valid
 * @param dimensions
 */
export const dimensionsAreValid = (dimensions): boolean => {
  if (isUndefined(dimensions) || dimensions.length === 0) {
    return false;
  }
  if (dimensions.length === 2 && typeof dimensions[0] === 'number') {
    return dimensions[0] <= dimensions[1];
  } else {
    if (dimensions === '-' || typeof dimensions === 'number') return true;
    return dimensions?.every((dim) => dimensionsAreValid(dim));
  }
};

/**
 * Fill in gaps in the indices to ensure that every dimension has an index
 */
export const ensureIndicesFitDimensions = (indices, dimensions) => {
  // base case: if dimensions is an array of numbers, indices must be a number. Return that, or if it doesn't exist, the lower bound of the dimension
  if (typeof dimensions[0] === 'number') {
    return indices || dimensions[0];
  }
  // recursively ensure that the indices array is valid
  return dimensions.map((innerDimensions, index) =>
    ensureIndicesFitDimensions(indices ? indices[index] : undefined, innerDimensions),
  );
};

const is2DIndexValid = (index: number[], dimensions: [number, number][]): boolean => {
  if (index.length !== dimensions.length) return false;
  if (index.length === 0) return false;
  return index.every((currIdx, i) => {
    const [min, max] = dimensions[i];
    return currIdx >= min && currIdx <= max;
  });
};

const is3DIndexValid = (index: number[][], dimensions: [number, number][][]): boolean => {
  if (index.length !== dimensions.length) return false;
  if (index.length === 0) return false;
  return index.every((currIdx, i) => is2DIndexValid(currIdx, dimensions[i]));
};

type Input =
  | {
      index: number[];
      dimensions: [number, number][];
    }
  | {
      index: number[][];
      dimensions: [number, number][][];
    };

/**
 * Ensure that indices are between dimensions
 */
export const indicesAreValid = (input: Input): boolean => {
  const { index, dimensions } = input;
  if (index.length != dimensions.length) return false;
  if (index.length === 0) return false;

  if (typeof index[0] === 'number') {
    return is2DIndexValid(index as number[], dimensions as [number, number][]);
  } else {
    return is3DIndexValid(index as number[][], dimensions as [number, number][][]);
  }
};

/**
 * Define a function to update the state of both the dimensions and indices forms.
 */
export const generateNewState = (state, { index: dimensionIndex = [], newValue }: Action) => {
  // base case: if no more index remains, you have reached the element you want to update
  if (dimensionIndex.length === 0) {
    return newValue;
  }
  // separate the first index from the index array
  const [outerIndex, ...innerIndex] = dimensionIndex;
  // create a new element if the index is out of range
  if (outerIndex >= state.length) return [...state, newValue];
  // recursively update the state but only call the reducer if the element's index is in the index array. Then filter out any undefined elements.
  return state
    .map((dimension, index) =>
      index === outerIndex
        ? generateNewState(state[outerIndex], {
            index: innerIndex,
            newValue,
          })
        : dimension,
    )
    .filter((dimension) => dimension !== undefined);
};

/**
 * Recursively count the depth of the dimensions array. This is needed because TIA should submit a 2d array, while ADS submits a 3d array.
 */
export const countArrayDimensions = ({ type, items }): number => {
  if (type === 'array') {
    return countArrayDimensions(items) + 1;
  } else {
    return 0;
  }
};

/**
 * Function that creates an event handler, which will propagate the changes into the parent element
 */
const changeField = (onChange) => (e) => {
  onChange(e.target.value);
};

interface Field {
  type?: string;
  key: string;
  value: Todo;
  readOnly?: boolean;
  isRequired: boolean;
  minimum?: number;
  maximum?: number;
  default?: Todo;
  enum?: string[];
  oneOf?: Todo[];
}

/**
 * Generate the input element based on the defined field type
 */
export const useComponent = (field: Field, onChange: (newValue: Todo, error?: Todo) => void) => {
  const { formatMessage } = useIntl();
  switch (field.type) {
    case 'string':
      return (
        <input
          key={field.key}
          value={field.value}
          onChange={changeField(onChange)}
          type='text'
          disabled={!!field.readOnly}
          required={field.isRequired}
        />
      );
    case 'integer':
    case 'number':
      return (
        <input
          key={field.key}
          value={field.value}
          onChange={changeField(onChange)}
          type='number'
          min={field.minimum}
          max={field.maximum}
          required={field.isRequired}
        />
      );
    case 'boolean':
      return (
        <NewRadio
          key={field.key}
          value={field.value === '' ? field.default ?? true : field.value}
          inputName={field.key}
          onChange={onChange}
          translateOptionsLabels={true}
          required={field.isRequired}
        />
      );
    case 'array':
      return (
        <input
          key={field.key}
          value={field.value}
          onChange={changeField(onChange)}
          type='text'
          required={field.isRequired}
        />
      );
    case 'enum':
    case undefined:
      if (field.enum)
        return (
          <ReactSelect
            key={field.key}
            styles={{
              container: (baseStyles) => ({ ...baseStyles, marginTop: '9px' }),
              input: (baseStyles) => ({ ...baseStyles, height: '32px' }),
            }}
            onChange={(newValue: Todo) => onChange(newValue.value)}
            options={field.enum.map((item) => ({ value: item, label: item }))}
            value={{ value: field.value, label: field.value }}
            required={field.isRequired}
          />
        );
      break;
    case 'object':
      if (!isUndefined(field.oneOf)) {
        const objectValues = field.value || { name: '' };

        /**
         * handle changing values in subfields
         */
        const onObjectChange = (fieldName) => (value) =>
          onChange({
            ...objectValues,
            [fieldName]: value,
          });

        const allValues = field.oneOf.flatMap((entry) => entry.properties.name.enum);
        const dropdown = (
          <DataPointFormField
            key={`${field.key}-name`}
            field={{
              ...field,
              type: 'enum',
              enum: allValues,
              value: objectValues.name,
            }}
            onChange={onObjectChange('name')}
          />
        );

        const additionalFields = field.oneOf.find(
          (entry) => entry.properties.name.enum.indexOf(field.value?.name) >= 0,
        )?.properties;
        if (isUndefined(additionalFields)) return [dropdown];
        const { name, ...restFields } = additionalFields;
        return [
          dropdown,
          ...Object.keys(restFields).map((thisField) => {
            // FIXME: this is just a temporary solution to catch the case where the message of the label is not defined
            let label = labels[thisField];
            if (label === undefined) {
              const titleCased = thisField.replace(/([A-Z])/g, ' $1');
              label = titleCased.charAt(0).toUpperCase() + titleCased.slice(1);
            } else {
              label = formatMessage(label);
            }

            const isNumType = ['integer', 'number'].includes(restFields[thisField].type);
            const fieldOnChange = onObjectChange(thisField);
            const wrapped = (value: string) => fieldOnChange(isNumType ? parseInt(value) : value);

            return (
              <DataPointFormField
                key={`${field.key}-${thisField}`}
                field={{
                  ...restFields[thisField],
                  label,
                  value: objectValues[thisField] ?? '',
                }}
                onChange={wrapped}
              />
            );
          }),
        ];
      }
    default:
      return <input value={field.value} onChange={changeField(onChange)} type='text' />;
  }
};

export const isValidJSON = (jsonStr: string): boolean => {
  if (typeof jsonStr !== 'string') {
    return false;
  }

  try {
    const parsed = JSON.parse(jsonStr);
    return typeof parsed === 'object' && parsed !== null;
  } catch (_) {
    return false;
  }
};
