import {
  MIN_RANGE_DELTA_MS as MIN_MS,
  MAX_RANGE_DELTA_MS as MAX_MS,
  MIN_RANGE_STEP_MS as STEP_MS,
} from '../../../schema/analyzeSessions';
import { debounce } from 'lodash';
import React, { useState, useCallback, useMemo } from 'react';
import {
  Container,
  RangeContainer,
  TimeDisplay,
  ButtonContainer,
  Button,
} from './InputRange.styled';

interface TimeRangeInputProps {
  onChange: (milliseconds: number) => void;
  initialValue?: number;
  className?: string;
}

export const TimeRangeInput: React.FC<TimeRangeInputProps> = ({
  onChange,
  initialValue = MIN_MS,
  className,
}) => {
  const [value, setValue] = useState(Math.max(MIN_MS, Math.min(MAX_MS, initialValue)));

  const formatTime = useCallback((ms: number) => {
    const minutes = Math.floor(ms / (60 * 1000));
    const seconds = Math.floor((ms % (60 * 1000)) / 1000);
    const milliseconds = ms % 1000;

    return {
      minutes: minutes.toString().padStart(2, '0'),
      seconds: seconds.toString().padStart(2, '0'),
      milliseconds: milliseconds.toString().padStart(3, '0'),
    };
  }, []);

  const debouncedOnChange = useMemo(
    () =>
      debounce(
        (changedValue: number, onChangeCallback: (value: number) => void) => {
          onChangeCallback(changedValue);
        },
        300,
        { leading: false, trailing: true },
      ),
    [],
  );

  const handleChange = useCallback(
    (newValue: number) => {
      const roundedValue = Math.round(newValue); // Round the value
      const clampedValue = Math.max(MIN_MS, Math.min(MAX_MS, roundedValue));
      setValue(clampedValue);
      debouncedOnChange(clampedValue, onChange);
    },
    [debouncedOnChange, onChange],
  );

  const handleRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(Number(event.target.value));
  };

  const handleIncrement = () => {
    handleChange(value * 2);
  };

  const handleDecrement = () => {
    handleChange(value / 2);
  };

  const formattedTime = formatTime(value);

  return (
    <Container className={className}>
      <RangeContainer>
        <input
          type='range'
          min={MIN_MS}
          max={MAX_MS}
          step={STEP_MS}
          value={value}
          onChange={handleRangeChange}
          style={{ flex: 1 }}
        />
        <TimeDisplay>
          <span>{formattedTime.minutes}m</span>
          <span>{formattedTime.seconds}s</span>
          <span>{formattedTime.milliseconds}ms</span>
        </TimeDisplay>
      </RangeContainer>

      <ButtonContainer>
        <Button
          className='button extra-button light-grey-white '
          onClick={handleIncrement}
          disabled={value >= MAX_MS}
        >
          <span className='fa fa-search-minus' />
        </Button>
        <Button
          className='button extra-button light-grey-white '
          onClick={handleDecrement}
          disabled={value <= MIN_MS}
        >
          <span className='fa fa-search-plus' />
        </Button>
      </ButtonContainer>
    </Container>
  );
};
