import React from 'react';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import { MenuItem, MenuLabel } from '.';
import * as helper from './helper';
import { PanelContextMenuItems, PanelContextMenuItemsProps } from './PanelContextMenu';
import { ColorMenu } from './ColorMenu';
import { ChartTypeMenu } from './ChartTypeMenu';
import { InterpolationTypeMenu } from './InterpolationTypeMenu';
import { ChartType, InterpolationType } from '../../../schema/analyzeSessions';
import { PanelSignal } from '../../../schema/type/panelSignal';

interface Props extends PanelContextMenuItemsProps {
  signalType: PanelSignal['type'];
  color: string;
  setColor: (color: string) => void;
  chartType: ChartType;
  setChartType: (chartType: ChartType) => void;
  interpolationType: InterpolationType;
  setInterpolationType: (interpolationType: InterpolationType) => void;
  removeSignal: () => void;
  children: React.ReactNode;
}

const signalsWithChartAndInterpolationType = new Set<PanelSignal['type']>([
  'bool',
  'double',
  'integer',
]);

export const PanelSignalContextMenu: React.FC<Props> = ({
  signalType,
  color,
  setColor,
  chartType,
  setChartType,
  interpolationType,
  setInterpolationType,
  removeSignal,
  panelSettings,
  setPanelSettings,
  children,
}) => (
  <RadixContextMenu.Root>
    <RadixContextMenu.Trigger>{children}</RadixContextMenu.Trigger>
    <RadixContextMenu.Portal>
      <RadixContextMenu.Content className={helper.contentClass}>
        <MenuLabel>Signal Options</MenuLabel>
        <MenuItem onSelect={removeSignal}>Remove</MenuItem>
        <ColorMenu color={color} setColor={setColor} />
        {signalsWithChartAndInterpolationType.has(signalType) && (
          <ChartTypeMenu chartType={chartType} setChartType={setChartType} />
        )}
        {signalsWithChartAndInterpolationType.has(signalType) && (
          <InterpolationTypeMenu
            interpolationType={interpolationType}
            setInterpolationType={setInterpolationType}
          />
        )}
        <RadixContextMenu.Separator className='h-px bg-verdigris m-1' />
        <PanelContextMenuItems panelSettings={panelSettings} setPanelSettings={setPanelSettings} />
      </RadixContextMenu.Content>
    </RadixContextMenu.Portal>
  </RadixContextMenu.Root>
);
