import React from 'react';
import Select, { components, SingleValue, MultiValue } from 'react-select';
import { customStyles } from './AddSignalInput.styled';

export interface Item {
  id: number | string;
  label: string;
}

interface Option {
  value: string | number;
  label: string;
}

interface CustomSelectProps {
  items: Item[];
  selectedItems?: Item[];
  onChange: (item: Item | Item[]) => void;
  placeholder?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  value?: Option[];
}

export const AddSignalInput: React.FC<CustomSelectProps> = ({
  items,
  selectedItems = [],
  onChange,
  placeholder = 'Select an item...',
  isDisabled = false,
  isMulti = false,
  value = [],
}) => {
  const availableItems = items.filter(
    (item) => !selectedItems.some((selected) => selected.id === item.id),
  );

  const options: Option[] = availableItems.map((item) => ({
    value: item.id,
    label: item.label,
  }));

  const handleChange = (option: SingleValue<Option> | MultiValue<Option>) => {
    if (isMulti) {
      const selectedOptions = option as MultiValue<Option>;
      const selectedItemsObj = selectedOptions.map((opt) => ({
        id: opt.value,
        label: opt.label,
      }));
      onChange(selectedItemsObj);
    } else {
      const selectedOption = option as SingleValue<Option>;
      if (selectedOption) {
        const selectedItem: Item = {
          id: selectedOption.value,
          label: selectedOption.label,
        };
        onChange(selectedItem);
      }
    }
  };

  return (
    <div className='select-container mt-2'>
      <Select
        components={{
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <i className='fa fa-plus' />
            </components.DropdownIndicator>
          ),
        }}
        options={options}
        value={value}
        isDisabled={isDisabled}
        placeholder={placeholder}
        styles={customStyles}
        className='basic-multi-select'
        classNamePrefix='select'
        isMulti={isMulti}
        onChange={handleChange}
        menuPosition='fixed' // Keeps menu open in the same position
        menuPortalTarget={document.body} // Ensures it opens outside parent elements
      />
    </div>
  );
};
