"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeEvery } from "redux-saga/effects";
import { AppReducerMapBuilder } from "./util";
import { transformErrorToMessageString } from "../../../../common/utils";
import { deletePanel } from "../../core/api/analyzer";
import * as norm from "../../schema/normalized";
import { sendNotification } from "./notifications.module";
import notificationMessages from "../../messages/notification.message";
import * as notification from "../../core/notifications";
export const deleteSessionPanel = createAction(
  "delete session panel",
  (sessionId, panelId) => ({
    payload: { sessionId, panelId }
  })
);
export const deleteSessionPanelSuccess = createAction(
  "delete session panel success",
  (panelId) => ({
    payload: panelId
  })
);
export const reducer = AppReducerMapBuilder.new().add(deleteSessionPanelSuccess, (state, panelId) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    panels: __spreadValues({}, norm.remove(state.analyzer.panels, panelId))
  })
})).build();
export function* deleteSessionPanelSaga({ payload: { sessionId, panelId } }) {
  const { response, error } = yield call(deletePanel, sessionId, panelId);
  if (response) {
    yield put(deleteSessionPanelSuccess(panelId));
    yield put(
      sendNotification(
        notificationMessages.successfully_changed_event,
        notificationMessages.successfully_changed_event_description,
        notification.NOTIFICATION_EVENT
      )
    );
  } else {
    const errorString = transformErrorToMessageString(error);
    yield put(
      sendNotification(
        __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
          values: { error: errorString }
        }),
        null,
        notification.NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchDeleteSessionPanel() {
  yield takeEvery(deleteSessionPanel, deleteSessionPanelSaga);
}
