import { createReducer } from 'redux-act';

import * as accountPasswordModule from './modules/profile.password.toggleDisplay.module';
import * as adminAccountFetchModule from './modules/admin.account.fetch.module';
import * as adminAccountsFetchModule from './modules/admin.accounts.fetch.module';
import * as adminAccountUpdateModule from './modules/admin.account.update.module';
import * as adminLocationFetchModule from './modules/admin.location.fetch.module';
import * as adminLocationRemoveModule from './modules/admin.location.remove.module';
import * as adminLocationsFetchModule from './modules/admin.locations.fetch.module';
import * as adminLocationsPaginationFetchModule from './modules/admin.locations.pagination.fetch.module';
import * as adminLocationsSortFilterModule from './modules/admin.locations.sort.filter.module';
import * as adminLocationUpdateModule from './modules/admin.location.update.module';
import * as adminOrganizationCreateModule from './modules/admin.organization.create.module';
import * as adminOrganizationFetchModule from './modules/admin.organization.fetch.module';
import * as adminOrganizationRemoveModule from './modules/admin.organization.remove.module';
import * as adminOrganizationsAllFetchModule from './modules/admin.organizations.all.fetch.module';
import * as adminOrganizationsFetchModule from './modules/admin.organizations.fetch.module';
import * as adminOrganizationsPaginationFetchModule from './modules/admin.organizations.pagination.fetch.module';
import * as adminOrganizationsSortFilterModule from './modules/admin.organizations.sort.filter.module';
import * as adminOrganizationUpdateModule from './modules/admin.organization.update.module';
import * as adminRoleFetchModule from './modules/admin.role.fetch.module';
import * as adminRolesFetchModule from './modules/admin.roles.fetch.module';
import * as adminUserFetchModule from './modules/admin.user.fetch.module';
import * as adminUsersFetchModule from './modules/admin.users.fetch.module';
import * as adminUsersSortFilterModule from './modules/admin.users.sort.filter.module';
import * as adminUserTabsModule from './modules/admin.user.tabs.module';
import * as adminUserUpdateModule from './modules/admin.user.update.module';
import * as anomalyFetchModule from './modules/anomaly.fetch.module';
import * as appConfigModule from './modules/appConfig.module';
import * as authModule from './modules/auth.module';
import * as batchDetailsFetchModule from './modules/batch.details.fetch.module';
import * as batchDetailsFilterDatesModule from './modules/batch.details.filter.dates.module';
import * as batchFetchModule from './modules/batch.fetch.module';
import * as batchSortFilterModule from './modules/batch.sort.filter.module';
import * as calendarCloneModule from './modules/calendars.clone.module';
import * as calendarModeModule from './modules/calendar.mode.module';
import * as calendarsAvailableModule from './modules/calendars.available.module';
import * as calendarsCreateEventModule from './modules/calendars.create.event.module';
import * as calendarsCreateModule from './modules/calendars.create.module';
import * as calendarsDeleteEventModule from './modules/calendars.delete.event.module';
import * as calendarsDeleteModule from './modules/calendars.delete.module';
import * as calendarsFetchEventsModule from './modules/calendars.fetch.calendarEvents.module';
import * as calendarsFetchModule from './modules/calendars.fetch.module';
import * as calendarsQueryModule from './modules/calendars.query.module';
import * as calendarsUpdateEventModule from './modules/calendars.update.event.module';
import * as calendarsUpdateModule from './modules/calendars.update.module';
import * as componentsVisibilityModule from './modules/components.visibility.module';
import * as configRepoCallbackReceiveModule from './modules/configRepo.callback.receive.module';
import * as configRepoExplorerDisabledListModule from './modules/configRepoExplorer.disabled.list.module';
import * as configRepoExplorerFetchModule from './modules/configRepoExplorer.fetch.module';
import * as configRepoExplorerResetModule from './modules/configRepoExplorer.reset.module';
import * as configRepoExplorerSelectModule from './modules/configRepoExplorer.select.module';
import * as configRepoExplorerSortFilterModule from './modules/configRepoExplorer.sort.filter.module';
import * as configRepoExplorerViewModule from './modules/configRepoExplorer.view.module';
import * as dashboardCalendarModule from './modules/dashboard.calendar.module';
import * as dashboardEventCommentModule from './modules/dashboard.event.comment.submit.module';
import * as dashboardEventExpandModule from './modules/dashboard.event.expand.module';
import * as dashboardEventFetchClusterModule from './modules/dashboard.event.fetch.cluster.module';
import * as dashboardEventFetchPayloadModule from './modules/dashboard.event.fetch.payload.module';
import * as dashboardEventReceiveCommentModule from './modules/dashboard.event.comment.receive.module';
import * as dashboardEventsFilterDatesModule from './modules/dashboard.events.filter.dates.module';
import * as dashboardFetchEventsModule from './modules/dashboard.fetch.events.module';
import * as dashboardFilterQueryModule from './modules/dashboard.events.filter.query.module';
import * as dashboardFilterStatusModule from './modules/dashboard.events.filter.module';
import * as dashboardLoadMOreEventsModule from './modules/dashboard.loadMode.events.module';
import * as dashboardOeeFetchModule from './modules/dashboard.oee.fetch.module';
import * as dataPointMetricFetchModule from './modules/dataPoint.metric.fetch.module';
import * as dataPointMetricResetModule from './modules/dataPoint.metric.reset.module';
import * as dataPointMetricSetTimezoneModule from './modules/dataPoint.metric.set.timezone.module';
import * as datatronActiveModule from './modules/datatron.active.module';
import * as datatronAssistantRemoveModule from './modules/datatron.assistant.remove.module';
import * as datatronDeviceActiveModule from './modules/datatron.devices.active.module';
import * as datatronDeviceClassesFetchModule from './modules/datatron.deviceClasses.fetch.module';
import * as datatronDeviceQuickCommandModule from './modules/datatron.devices.quickCommands.module';
import * as datatronDevicesAddModule from './modules/datatron.devices.add.module';
import * as datatronDevicesArchiveModule from './modules/datatron.devices.archive.module';
import * as datatronDevicesCommandModule from './modules/datatron.devices.command.module';
import * as datatronDevicesDataPointArchiveModule from './modules/datatron.devices.dataPoints.archive.module';
import * as datatronDevicesDataPointUnarchiveModule from './modules/datatron.devices.dataPoints.unarchive.module';
import * as datatronDevicesDataPointCreateModule from './modules/datatron.devices.dataPoints.create.module';
import * as datatronDevicesDataPointsFetchModule from './modules/datatron.devices.dataPoints.fetch.module';
import * as datatronDevicesDataPointsHighlightModule from './modules/datatron.devices.dataPoints.highlight.module';
import * as datatronDevicesDataPointSortFilterModule from './modules/datatron.devices.dataPoints.sort.filter.module';
import * as datatronDevicesDataPointUpdateModule from './modules/datatron.devices.dataPoints.update.module';
import * as datatronDevicesDataPointUpdateBulkModule from './modules/datatron.devices.dataPoints.update.bulk.module';
import * as datatronDevicesDataPointUpdateBulkExcelModule from './modules/datatron.devices.dataPoints.update.bulk.excel.module';
import * as datatronDevicesFetchModule from './modules/datatron.devices.fetch.module';
import * as datatronDevicesUpdateModule from './modules/datatron.devices.update.module';
import * as datatronDeviceTypesFetchModule from './modules/datatron.deviceTypes.fetch.module';
import * as datatronFetchModule from './modules/datatron.fetch.module';
import * as datatronGraphTabsModule from './modules/datatron.graph.tabs.module';
import * as datatronNewAssistantAddModule from './modules/datatron.newAssistant.add.module';
import * as datatronTabsModule from './modules/datatron.tabs.module';
import * as datatronUpdateModule from './modules/datatron.update.module';
import * as experimentsCreateModule from './modules/experiments.create.module';
import * as experimentsFetchModule from './modules/experiments.fetch.module';
import * as experimentsFetchPaginationModule from './modules/experiments.fetch.pagination.module';
import * as experimentsFilterModule from './modules/experiments.filter.module';
import * as experimentsPageTabsModule from './modules/experimentsPage.tabs.module';
import * as experimentsUpdateModule from './modules/experiments.update.module';
import * as exportWidgetVerificationModule from './modules/export.widget.verification.module';
import * as fetchMeasurementsByExperimentModule from './modules/experimentsPage.measurementsByExperiment.fetch.module';
import * as hideEvent from './modules/maintenance.columns.event.hide.module';
import * as inventoryDatatronReceiveModule from './modules/inventory.datatron.receive.module';
import * as inventoryFetchModule from './modules/inventory.fetch.module';
import * as inventoryMachineReceiveModule from './modules/inventory.machine.receive.module';
import * as inventoryPreviewFetchModule from './modules/inventory.preview.fetch.module';
import * as inventoryPreviewModule from './modules/inventory.preview.module';
import * as locationFetchModule from './modules/location.fetch.module';
import * as locationFilterModule from './modules/location.filter.module';
import * as locationPickerFetchModule from './modules/locationPicker.fetch.module';
import * as locationPickerInitializeModule from './modules/locationPicker.initialize.module';
import * as locationPickerSelectModule from './modules/locationPicker.select.module';
import * as machineAssistantRemoveModule from './modules/machine.assistant.remove.module';
import * as machineCalendarsChangeModule from './modules/machine.calendars.change.module';
import * as machineCalendarsFetchEventsModule from './modules/machine.calendars.fetch.events.module';
import * as machineClusterExpandModule from './modules/machine.cluster.expand.module';
import * as machineClustersGroupExpandModule from './modules/machine.clusters.group.expand.module';
import * as machineDataPointsFetchModule from './modules/machine.dataPoints.fetch.module';
import * as machineDataPointsFilterModule from './modules/machine.dataPoints.filter.module';
import * as machineDataPointsPaginationModule from './modules/machine.dataPoints.pagination.module';
import * as machineDataPointsSubscribeModule from './modules/machine.dataPoints.subscribe.module';
import * as machineDataPointsUnsubscribeModule from './modules/machine.dataPoints.unsubscribe.module';
import * as machineDataPointsUpdateModule from './modules/machine.dataPoints.update.module';
import * as machineEventReceiveCommentModule from './modules/machine.event.comment.receive.module';
import * as machineFetch from './modules/machine.fetch.module';
import * as machineFetchClusterModule from './modules/machine.fetch.cluster.module';
import * as machineFetchClustersGroupModule from './modules/machine.fetch.clusters.group.module';
import * as machineFetchClustersSummaryModule from './modules/machine.fetch.clusters.summary.module';
import * as machineFetchEvents from './modules/machine.fetch.events.module';
import * as machineFetchSummaryGroupEventsCountModule from './modules/machine.fetch.summaryGroup.eventsCount.module';
import * as machineFetchSummaryGroupLoadMoreModule from './modules/machine.fetch.summaryGroup.loadMore.module';
import * as machineFetchSummaryGroupModule from './modules/machine.fetch.summaryGroup.module';
import * as machineFetchSummaryModule from './modules/machine.fetch.summary.module';
import * as machineGraphTabsModule from './modules/machine.graph.tabs.module';
import * as machineMaintenanceFilterModule from './modules/machine.maintenance.filter.module';
import * as machineNewAssistantAddModule from './modules/machine.newAssistant.add.module';
import * as machineOeeFetchModule from './modules/machine.oee.fetch.module';
import * as machineSensorsExpandModule from './modules/machine.sensors.expand.module';
import * as machinesFetchModule from './modules/machines.fetch.module';
import * as machineSummaryExpandModule from './modules/machine.summary.expand.module';
import * as machineSummaryGroupSwitchModeModule from './modules/machine.summaryGroup.switch.mode.module';
import * as machineSummarySortFilterModule from './modules/machine.summary.sort.filter.module';
import * as machineTabsModule from './modules/machine.tabs.module';
import * as machineToolsFetchModule from './modules/machine.tools.fetch.module';
import * as machineToolsFilterModule from './modules/machine.tools.filter.module';
import * as machineUpdateModule from './modules/machine.update.module';
import * as maintenanceColumns from './modules/maintenance.columns.module';
import * as maintenanceColumnsCreateActivity from './modules/maintenance.columns.activity.create.module';
import * as maintenanceColumnsMoveActivity from './modules/maintenance.columns.activity.move.module';
import * as maintenanceColumnsUpdateActivity from './modules/maintenance.columns.activity.update.module';
import * as maintenanceDnDModule from './modules/maintenance.columns.dnd.module';
import * as maintenanceEventReceiveCommentModule from './modules/maintenance.event.comment.receive.module';
import * as maintenanceExpandedModule from './modules/maintenance.expanded.module';
import * as maintenanceFetchActivitiesModule from './modules/maintenance.fetch.activities.module';
import * as maintenanceFetchEventCountModule from './modules/maintenance.fetch.eventsCount.module';
import * as maintenanceFetchEventsModule from './modules/maintenance.fetch.events.module';
import * as maintenanceLoadMoreEventsModule from './modules/maintenance.loadMore.events.module';
import * as measurementsArchiveModule from './modules/measurements.archive.module';
import * as measurementsFetchModule from './modules/measurements.fetch.module';
import * as measurementsFetchPaginationModule from './modules/measurements.fetch.pagination.module';
import * as measurementsFilterModule from './modules/measurements.filter.module';
import * as measurementsMergeModule from './modules/measurements.merge.module';
import * as modalsModule from './modules/modals.module';
import * as newDatatronCreateModule from './modules/newDatatron.create.module';
import * as newMachineCreate from './modules/newMachine.create.module';
import * as notificationsModule from './modules/notifications.module';
import * as ouFetchModule from './modules/ou.fetch.module';
import * as pageModule from './modules/page.module';
import * as pathButtonTempLocationPathWriteModule from './modules/pathButton.tempLocationPath.write.module';
import * as profileLocationChangeModule from './modules/profile.location.change.module';
import * as profilePasswordChangeModule from './modules/profile.password.change.module';
import * as profileRolesReceiveModule from './modules/profile.roles.receive.module';
import * as profileSaveModule from './modules/profile.save.module';
import * as reportCreateModule from './modules/report.create.module';
import * as reportDuplicateModule from './modules/report.duplicate.module';
import * as reportIndicatorsModule from './modules/report.indicators.module';
import * as reportsEntryFetchModule from './modules/reports.entry.fetch.module';
import * as reportsFetchModule from './modules/reports.fetch.module';
import * as reportsGraphTabsModule from './modules/reports.graph.tabs.module';
import * as reportsRemoveModule from './modules/reports.remove.module';
import * as reportsSliderModule from './modules/reports.slider.module';
import * as reportsTabsAddModule from './modules/reportsTabs.add.module';
import * as reportsTabsEditModule from './modules/reportsTabs.edit.module';
import * as reportsTabsFetchModule from './modules/reportsTabs.fetch.module';
import * as reportsTabsRemoveModule from './modules/reportsTabs.remove.module';
import * as reportsTabsSelectModule from './modules/reportsTabs.select.module';
import * as reportUpdateModule from './modules/report.update.module';
import * as searchModule from './modules/search.module';
import * as states from './state/app.state';
import * as toolsCreateModule from './modules/tools.create.module';
import * as toolsEditModule from './modules/tools.edit.module';
import * as toolsFetchModule from './modules/tools.fetch.module';
import * as toolsFilterModule from './modules/tools.filter.module';
import * as userModule from './modules/user.module';
import * as usersFetchModule from './modules/users.fetch.module';
import * as usersOrganizationSearch from './modules/users.organization.search.module';
import * as usersSearch from './modules/users.search.module';
import * as analyzerFetch from './modules/analyzer.fetch.module';
import * as analyzerSessionFetch from './modules/analyzer.session.fetch.module';
import * as analyzerAnomalyFetch from './modules/analyzer.anomaly.fetch.module';
import * as analyzerSessionUpdate from './modules/analyzer.sessions.update.module';
import * as analyzerSessionPanelUpdate from './modules/analyzer.sessions.panel.update.module';
import * as analyzerSessionPanelCreate from './modules/analyzer.sessions.panel.create.module';
import * as analyzerSessionPanelDelete from './modules/analyzer.sessions.panel.delete.module';
import * as analyzerSessionQuickCommand from './modules/analyzer.sessions.quickCommands.module';
import * as analyzerSessionPanelMouse from './modules/analyzer.sessions.panel.mouse.module';

export default createReducer(
  {
    ...accountPasswordModule.reducer,
    ...adminAccountFetchModule.reducer,
    ...adminAccountsFetchModule.reducer,
    ...adminAccountUpdateModule.reducer,
    ...adminLocationFetchModule.reducer,
    ...adminLocationRemoveModule.reducer,
    ...adminLocationsFetchModule.reducer,
    ...adminLocationsPaginationFetchModule.reducer,
    ...adminLocationsSortFilterModule.reducer,
    ...adminLocationUpdateModule.reducer,
    ...adminOrganizationCreateModule.reducer,
    ...adminOrganizationFetchModule.reducer,
    ...adminOrganizationRemoveModule.reducer,
    ...adminOrganizationsAllFetchModule.reducer,
    ...adminOrganizationsFetchModule.reducer,
    ...adminOrganizationsPaginationFetchModule.reducer,
    ...adminOrganizationsSortFilterModule.reducer,
    ...adminOrganizationUpdateModule.reducer,
    ...adminRoleFetchModule.reducer,
    ...adminRolesFetchModule.reducer,
    ...adminUserFetchModule.reducer,
    ...adminUsersFetchModule.reducer,
    ...adminUsersSortFilterModule.reducer,
    ...adminUserTabsModule.reducer,
    ...adminUserUpdateModule.reducer,
    ...anomalyFetchModule.reducer,
    ...appConfigModule.reducer,
    ...authModule.reducer,
    ...batchDetailsFetchModule.reducer,
    ...batchDetailsFilterDatesModule.reducer,
    ...batchFetchModule.reducer,
    ...batchSortFilterModule.reducer,
    ...calendarCloneModule.reducer,
    ...calendarModeModule.reducer,
    ...calendarsAvailableModule.reducer,
    ...calendarsCreateEventModule.reducer,
    ...calendarsCreateModule.reducer,
    ...calendarsDeleteEventModule.reducer,
    ...calendarsDeleteModule.reducer,
    ...calendarsFetchEventsModule.reducer,
    ...calendarsFetchModule.reducer,
    ...calendarsQueryModule.reducer,
    ...calendarsUpdateEventModule.reducer,
    ...calendarsUpdateModule.reducer,
    ...componentsVisibilityModule.reducer,
    ...configRepoCallbackReceiveModule.reducer,
    ...configRepoExplorerDisabledListModule.reducer,
    ...configRepoExplorerFetchModule.reducer,
    ...configRepoExplorerResetModule.reducer,
    ...configRepoExplorerSelectModule.reducer,
    ...configRepoExplorerSortFilterModule.reducer,
    ...configRepoExplorerViewModule.reducer,
    ...dashboardCalendarModule.reducer,
    ...dashboardEventCommentModule.reducer,
    ...dashboardEventExpandModule.reducer,
    ...dashboardEventFetchClusterModule.reducer,
    ...dashboardEventFetchPayloadModule.reducer,
    ...dashboardEventReceiveCommentModule.reducer,
    ...dashboardEventsFilterDatesModule.reducer,
    ...dashboardFetchEventsModule.reducer,
    ...dashboardFilterQueryModule.reducer,
    ...dashboardFilterStatusModule.reducer,
    ...dashboardLoadMOreEventsModule.reducer,
    ...dashboardOeeFetchModule.reducer,
    ...dataPointMetricFetchModule.reducer,
    ...dataPointMetricResetModule.reducer,
    ...dataPointMetricSetTimezoneModule.reducer,
    ...datatronActiveModule.reducer,
    ...datatronAssistantRemoveModule.reducer,
    ...datatronDeviceActiveModule.reducer,
    ...datatronDeviceClassesFetchModule.reducer,
    ...datatronDeviceQuickCommandModule.reducer,
    ...datatronDevicesAddModule.reducer,
    ...datatronDevicesArchiveModule.reducer,
    ...datatronDevicesCommandModule.reducer,
    ...datatronDevicesDataPointArchiveModule.reducer,
    ...datatronDevicesDataPointUnarchiveModule.reducer,
    ...datatronDevicesDataPointCreateModule.reducer,
    ...datatronDevicesDataPointsFetchModule.reducer,
    ...datatronDevicesDataPointsHighlightModule.reducer,
    ...datatronDevicesDataPointSortFilterModule.reducer,
    ...datatronDevicesDataPointUpdateModule.reducer,
    ...datatronDevicesDataPointUpdateBulkModule.reducer,
    ...datatronDevicesDataPointUpdateBulkExcelModule.reducer,
    ...datatronDevicesFetchModule.reducer,
    ...datatronDevicesUpdateModule.reducer,
    ...datatronDeviceTypesFetchModule.reducer,
    ...datatronFetchModule.reducer,
    ...datatronGraphTabsModule.reducer,
    ...datatronNewAssistantAddModule.reducer,
    ...datatronTabsModule.reducer,
    ...datatronUpdateModule.reducer,
    ...experimentsCreateModule.reducer,
    ...experimentsFetchModule.reducer,
    ...experimentsFetchPaginationModule.reducer,
    ...experimentsFilterModule.reducer,
    ...experimentsPageTabsModule.reducer,
    ...experimentsUpdateModule.reducer,
    ...exportWidgetVerificationModule.reducer,
    ...fetchMeasurementsByExperimentModule.reducer,
    ...hideEvent.reducer,
    ...inventoryDatatronReceiveModule.reducer,
    ...inventoryFetchModule.reducer,
    ...inventoryMachineReceiveModule.reducer,
    ...inventoryPreviewFetchModule.reducer,
    ...inventoryPreviewModule.reducer,
    ...locationFetchModule.reducer,
    ...locationFilterModule.reducer,
    ...locationPickerFetchModule.reducer,
    ...locationPickerInitializeModule.reducer,
    ...locationPickerSelectModule.reducer,
    ...machineAssistantRemoveModule.reducer,
    ...machineCalendarsChangeModule.reducer,
    ...machineCalendarsFetchEventsModule.reducer,
    ...machineClusterExpandModule.reducer,
    ...machineClustersGroupExpandModule.reducer,
    ...machineDataPointsFetchModule.reducer,
    ...machineDataPointsFilterModule.reducer,
    ...machineDataPointsPaginationModule.reducer,
    ...machineDataPointsSubscribeModule.reducer,
    ...machineDataPointsUnsubscribeModule.reducer,
    ...machineDataPointsUpdateModule.reducer,
    ...machineEventReceiveCommentModule.reducer,
    ...machineFetch.reducer,
    ...machineFetchClusterModule.reducer,
    ...machineFetchClustersGroupModule.reducer,
    ...machineFetchClustersSummaryModule.reducer,
    ...machineFetchEvents.reducer,
    ...machineFetchSummaryGroupEventsCountModule.reducer,
    ...machineFetchSummaryGroupLoadMoreModule.reducer,
    ...machineFetchSummaryGroupModule.reducer,
    ...machineFetchSummaryModule.reducer,
    ...machineGraphTabsModule.reducer,
    ...machineMaintenanceFilterModule.reducer,
    ...machineNewAssistantAddModule.reducer,
    ...machineOeeFetchModule.reducer,
    ...machineSensorsExpandModule.reducer,
    ...machinesFetchModule.reducer,
    ...machineSummaryExpandModule.reducer,
    ...machineSummaryGroupSwitchModeModule.reducer,
    ...machineSummarySortFilterModule.reducer,
    ...machineTabsModule.reducer,
    ...machineToolsFetchModule.reducer,
    ...machineToolsFilterModule.reducer,
    ...machineUpdateModule.reducer,
    ...maintenanceColumns.reducer,
    ...maintenanceColumnsCreateActivity.reducer,
    ...maintenanceColumnsMoveActivity.reducer,
    ...maintenanceColumnsUpdateActivity.reducer,
    ...maintenanceDnDModule.reducer,
    ...maintenanceEventReceiveCommentModule.reducer,
    ...maintenanceExpandedModule.reducer,
    ...maintenanceFetchActivitiesModule.reducer,
    ...maintenanceFetchEventCountModule.reducer,
    ...maintenanceFetchEventsModule.reducer,
    ...maintenanceLoadMoreEventsModule.reducer,
    ...measurementsArchiveModule.reducer,
    ...measurementsFetchModule.reducer,
    ...measurementsFetchPaginationModule.reducer,
    ...measurementsFilterModule.reducer,
    ...measurementsMergeModule.reducer,
    ...modalsModule.reducer,
    ...newDatatronCreateModule.reducer,
    ...newMachineCreate.reducer,
    ...notificationsModule.reducer,
    ...ouFetchModule.reducer,
    ...pageModule.reducer,
    ...pathButtonTempLocationPathWriteModule.reducer,
    ...profileLocationChangeModule.reducer,
    ...profilePasswordChangeModule.reducer,
    ...profileRolesReceiveModule.reducer,
    ...profileSaveModule.reducer,
    ...reportCreateModule.reducer,
    ...reportDuplicateModule.reducer,
    ...reportIndicatorsModule.reducer,
    ...reportsEntryFetchModule.reducer,
    ...reportsFetchModule.reducer,
    ...reportsGraphTabsModule.reducer,
    ...reportsRemoveModule.reducer,
    ...reportsSliderModule.reducer,
    ...reportsTabsAddModule.reducer,
    ...reportsTabsEditModule.reducer,
    ...reportsTabsFetchModule.reducer,
    ...reportsTabsRemoveModule.reducer,
    ...reportsTabsSelectModule.reducer,
    ...reportUpdateModule.reducer,
    ...searchModule.reducer,
    ...toolsCreateModule.reducer,
    ...toolsEditModule.reducer,
    ...toolsFetchModule.reducer,
    ...toolsFilterModule.reducer,
    ...userModule.reducer,
    ...usersFetchModule.reducer,
    ...usersOrganizationSearch.reducer,
    ...usersSearch.reducer,
    ...analyzerFetch.reducer,
    ...analyzerSessionFetch.reducer,
    ...analyzerSessionUpdate.reducer,
    ...analyzerSessionQuickCommand.reducer,
    ...analyzerSessionPanelUpdate.reducer,
    ...analyzerSessionPanelCreate.reducer,
    ...analyzerSessionPanelDelete.reducer,
    ...analyzerSessionPanelMouse.reducer,
    ...analyzerAnomalyFetch.reducer,
  },
  states.initialState,
);
