import React from 'react';
import { useDispatch } from 'react-redux';

import { injectIntl, WrappedComponentProps } from 'react-intl';
import TabsBar from '../../common/tabsBar/TabsBar';
import commonMessages from '../../../messages/common.messages';
import { TabCommands } from '../../../schema/type/session';
import { formatLocalDate } from '../../helper/time';
import { openModal } from '../../../redux/modules/modals.module';
import {
  ANALYZER_ANOMALY_PANEL_ADD,
  ANALYZER_DISPLAY_SESSION_OR_ANOMALY_DATA,
  ANALYZER_SESSION_COMMAND,
} from '../../../redux/constants/modals.constants';
import { createSession } from '../../../redux/modules/analyzer.sessions.create.module';

interface AnomalyActionsProps extends WrappedComponentProps {
  anomaly: {
    name: string;
    id: number;
    datatron_uuid: string;
    anomaly_from: string;
  };
}

export const AnomalyActions: React.FC<AnomalyActionsProps> = ({ intl, anomaly }) => {
  const dispatch = useDispatch();
  const tabName = `Anomaly Detection: ${formatLocalDate(new Date(anomaly.anomaly_from))}`;
  const mainButtons = [
    {
      title: tabName,
      isActive: true,
      onClick: () => {},
    },
  ];

  const extraButtons = [
    {
      title: intl.formatMessage(commonMessages.save),
      icon: 'fa fa-save',
      isActive: true,
      color: 'green',
      onClick: () => {
        dispatch(createSession(anomaly.datatron_uuid, tabName, []));
      },
    },
    {
      title: intl.formatMessage(commonMessages.details),
      icon: 'fa fa-info',
      isActive: true,
      color: 'info',
      onClick: () => {
        dispatch(
          openModal(ANALYZER_DISPLAY_SESSION_OR_ANOMALY_DATA, {
            data: anomaly,
          }),
        );
      },
    },
    {
      title: `${intl.formatMessage(commonMessages.add)} ${intl.formatMessage(commonMessages.panel)}`,
      isActive: true,
      onClick: () => dispatch(openModal(ANALYZER_ANOMALY_PANEL_ADD)),
      color: 'green',
      icon: 'fa fa-plus',
    },
    {
      title: intl.formatMessage(commonMessages.archive),
      icon: 'fa fa-archive',
      isActive: true,
      color: 'warning',
      onClick: () =>
        dispatch(
          openModal(ANALYZER_SESSION_COMMAND, {
            id: anomaly.id,
            command: TabCommands.archiveAnomaly,
          }),
        ),
    },
  ];

  return <TabsBar list={mainButtons} extraButtons={extraButtons} disableScroll />;
};

export default injectIntl(AnomalyActions);
