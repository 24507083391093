"use strict";
import { useMemo } from "react";
import { useSelector } from "react-redux";
export { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { NOT_LOADED } from "../signalChart/CursorLabel";
import { useChartData } from "../hooks/useChartData";
import { calculateCursorValue, getTimeRange } from "../signalChart/Helper";
import { dateToUnixNano } from "../../helper/time";
import { getPanelById, getTimeByPanelId } from "../../../redux/selectors/analyzer.selector";
export const useSignalData = (panelId, signalUuid) => {
  const { range_delta_ms, range_from } = useSelector((state) => getPanelById(state, panelId));
  const { cursorUnixNano } = useSelector((state) => getTimeByPanelId(state, panelId));
  const timeRange = useMemo(
    () => getTimeRange(range_from, range_delta_ms),
    [range_from, range_delta_ms]
  );
  const { signalData, isLoading, error } = useChartData(signalUuid, timeRange);
  return {
    signalData,
    isLoading,
    error,
    timeRange,
    cursorUnixNano,
    fromUnixNano: dateToUnixNano(timeRange.fromDate),
    toUnixNano: dateToUnixNano(timeRange.toDate),
    cursorValue: isLoading ? NOT_LOADED : calculateCursorValue(signalData == null ? void 0 : signalData.data, cursorUnixNano)
  };
};
