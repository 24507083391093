import React from 'react';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import { MenuItem } from '.';
import * as helper from './helper';

const SELECTABLE_COLORS = [
  {
    name: 'black',
    color: '#0f172a',
    className: 'hover:bg-[#0f172a] hover:text-white',
  },
  {
    name: 'red',
    color: '#dc2626',
    className: 'hover:bg-[#dc2626] hover:text-white',
  },
  {
    name: 'green',
    color: '#15803d',
    className: 'hover:bg-[#15803d] hover:text-white',
  },
  {
    name: 'blue',
    color: '#1d4ed8',
    className: 'hover:bg-[#1d4ed8] hover:text-white',
  },
  {
    name: 'purple',
    color: '#7e22ce',
    className: 'hover:bg-[#7e22ce] hover:text-white',
  },
  {
    name: 'cyan',
    color: '#0e7490',
    className: 'hover:bg-[#0e7490] hover:text-white',
  },
  {
    name: 'pink',
    color: '#be185d',
    className: 'hover:bg-[#be185d] hover:text-white',
  },
  {
    name: 'gray',
    color: '#374151',
    className: 'hover:bg-[#374151] hover:text-white',
  },
  {
    name: 'orange',
    color: '#c2410c',
    className: 'hover:bg-[#c2410c] hover:text-white',
  },
] as const;

interface Props {
  color: string;
  setColor: (color: string) => void;
}

export const ColorMenu: React.FC<Props> = ({ color, setColor }) => (
  <RadixContextMenu.Sub>
    <RadixContextMenu.SubTrigger
      className={`${helper.subTriggerClass} flex flex-row justify-between`}
    >
      <div>Change Color</div>
      <i className='fa fa-chevron-right' />
    </RadixContextMenu.SubTrigger>
    <RadixContextMenu.Portal>
      <RadixContextMenu.SubContent sideOffset={2} className={helper.contentClass}>
        {SELECTABLE_COLORS.map((selectableColor) => (
          <MenuItem
            key={selectableColor.name}
            className={`${helper.itemClass} ${selectableColor.className} capitalize gap-1`}
            onSelect={() => setColor(selectableColor.color)}
          >
            <div className='flex-grow'>{selectableColor.name}</div>
            <i
              className={
                color === selectableColor.color ? 'fa  fa-check visible' : 'fa  fa-check  invisible'
              }
            />
          </MenuItem>
        ))}
      </RadixContextMenu.SubContent>
    </RadixContextMenu.Portal>
  </RadixContextMenu.Sub>
);
