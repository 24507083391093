import React from 'react';
import { useSelector } from 'react-redux';
import { AreaChart, CartesianGrid, ResponsiveContainer, XAxis } from 'recharts';
import { getPanelById } from '../../../redux/selectors/analyzer.selector';

interface Props {
  panelId: number;
}

export const chartMargin = {
  top: 0,
  right: 30,
  left: 30,
  bottom: 30,
};

const cartesianGridProps = {
  strokeDasharray: '4',
  horizontal: false,
  vertical: true,
};

const formatTick = (value: number, showMilliseconds: boolean): string => {
  if (showMilliseconds) {
    return new Date(value / 1e6).toLocaleString('en-US', {
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  }
  return new Date(value / 1e6).toLocaleString('en-US', {
    hour12: false,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
};
const getTimeRange = (rangeFrom: Date | null, rangeDeltaMs: number | null) => {
  const DEFAULT_RANGE_MS = 60000;
  // If rangeFrom is null, use current date
  const fromDate = rangeFrom ? new Date(rangeFrom) : new Date();
  // If rangeDeltaMs is null, use 1 minute
  const deltaMs = rangeDeltaMs || DEFAULT_RANGE_MS;

  const toDate = new Date(fromDate.getTime() + deltaMs);

  return {
    fromDate,
    toDate,
  };
};
export const XAxisChart: React.FC<Props> = ({ panelId }) => {
  const { range_delta_ms: rangeDeltaMs, range_from: rangeFrom } = useSelector((state) =>
    getPanelById(state, panelId),
  );

  const timeRange = getTimeRange(rangeFrom, rangeDeltaMs);
  const fromUnixNano = timeRange.fromDate.getTime() * 1e6;
  const toUnixNano = timeRange.toDate.getTime() * 1e6;

  return (
    <div style={{ width: '100%', height: '50px' }}>
      <ResponsiveContainer width='100%' height='100%'>
        <AreaChart data={[]} margin={chartMargin}>
          <CartesianGrid {...cartesianGridProps} />
          <XAxis
            domain={[fromUnixNano, toUnixNano]}
            dataKey='systime'
            type='number'
            interval='preserveStartEnd'
            allowDataOverflow={true}
            tickCount={30}
            tickFormatter={(value: number) => formatTick(value, true)}
            minTickGap={20}
            height={20}
            tick={{
              fontSize: 12,
            }}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
