export const app = {
  prefix: '/app/',

  error: 'error',
  dashboard: 'dashboard',
  maintenance: 'maintenance',
  maintenancePrefix: 'maintenance/',
  inventory: 'inventory',
  reports: 'reports',
  reportId: ':reportId',
  sidePanel: 'sidePanel',
  machinesPrefix: 'machines/',
  machine: ':machine',
  newMachine: 'new',
  edit: 'edit',
  machineCalendars: 'machineCalendars',
  datatronPrefix: 'datatron/',
  datatron: ':datatron',
  newDatatron: 'new',
  profile: 'profile',
  ouPrefix: 'ou/',
  ou: ':ouId',
  tagsCloud: 'tagsCloud',
  locationPrefix: 'locations/',
  location: ':location',
  batches: 'batches',
  batch: ':batch',
  tools: 'tools',
  experiments: 'experiments',
  analyzers: 'analyzers',
  sessions: 'sessions',
  anomalies: 'anomalies',
  sessionId: ':sessionId',
  anomalyId: ':anomalyId',
  measurements: 'measurements',
  anomalyPrefix: 'anomaly/',
  anomalyEventId: ':eventId',
  adminPage: 'admin',
  adminPageAccounts: 'accounts',
  adminPageAccountId: ':accountId',
  adminPageOrganizations: 'organizations',
  adminPageOrganizationId: ':organizationId',
  adminPageLocations: 'locations',
  adminPageLocationId: ':locationId',
  adminPageUsers: 'users',
  adminPageUserId: ':userId',
  adminPageRoles: 'roles',
  adminPageRoleId: ':roleId',
  entryCreate: 'new',
  entryEdit: 'edit',
  entryView: 'details',
  unauthorizedPage: '401',
};

export const getLink = (path) => app.prefix + app[path];

export const getMachineLink = (id) => `${app.prefix}${app.inventory}/${app.machinesPrefix}${id}`;

export const getMachineEditLink = (id) =>
  `${app.prefix}${app.inventory}/${app.machinesPrefix}${id}/${app.edit}`;

export const getDatatronLink = (id) => `${app.prefix}${app.inventory}/${app.datatronPrefix}${id}`;

export const getDatatronEditLink = (id) =>
  `${app.prefix}${app.inventory}/${app.datatronPrefix}${id}/${app.edit}`;

export const getNewMachineLink = () =>
  `${app.prefix}${app.inventory}/${app.machinesPrefix}${app.newMachine}`;

export const getNewDatatronLink = () =>
  `${app.prefix}${app.inventory}/${app.datatronPrefix}${app.newDatatron}`;

export const getArchiveLink = () => `${app.prefix}${app.maintenancePrefix}${app.archive}`;

export const getLocationLink = (id) => `${app.prefix}${app.locationPrefix}${id}`;

export const getBatchDetailedLink = (id) => `${app.prefix}${app.batches}/${id}`;

export const getAnomalyLink = (eventId) => `${app.prefix}${app.anomalyPrefix}${eventId}`;

export const getBatchesLink = () => app.prefix + app.batches;

export const getDashboardLink = () => getLink(app.dashboard);

export const getMachineCalendarsLink = () => `${app.prefix}${app.machineCalendars}`;

export const getInventoryLink = () => getLink(app.inventory);

export const getOULink = (id) => `${app.prefix}${app.ouPrefix}${id}`;

export const getAdminPageLink = () => app.prefix + app.adminPage;

export const getAdminAccountsLink = () => `${app.prefix + app.adminPage}/${app.adminPageAccounts}`;
export const getAdminCreateAccountLink = () => `${getAdminAccountsLink()}/${app.entryCreate}`;
export const getAdminEditAccountLink = (id) => `${getAdminAccountsLink()}/${id}/${app.entryEdit}`;

export const getAdminOrganizationsLink = () =>
  `${app.prefix + app.adminPage}/${app.adminPageOrganizations}`;
export const getAdminCreateOrganizationLink = () =>
  `${getAdminOrganizationsLink()}/${app.entryCreate}`;
export const getAdminEditOrganizationLink = (id) =>
  `${getAdminOrganizationsLink()}/${id}/${app.entryEdit}`;

export const getAdminLocationsLink = () =>
  `${app.prefix + app.adminPage}/${app.adminPageLocations}`;
export const getAdminCreateLocationLink = () => `${getAdminLocationsLink()}/${app.entryCreate}`;
export const getAdminLocationLink = (id) => `${getAdminLocationsLink()}/${id}/${app.entryView}`;
export const getAdminEditLocationLink = (id) => `${getAdminLocationsLink()}/${id}/${app.entryEdit}`;

export const getAdminUsersLink = () => `${app.prefix + app.adminPage}/${app.adminPageUsers}`;
export const getAdminCreateUserLink = () => `${getAdminUsersLink()}/${app.entryCreate}`;
export const getAdminUserLink = (id) => `${getAdminUsersLink()}/${id}/${app.entryView}`;
export const getAdminEditUserLink = (id) => `${getAdminUsersLink()}/${id}/${app.entryEdit}`;

export const getAdminRolesLink = () => `${app.prefix + app.adminPage}/${app.adminPageRoles}`;
export const getAdminRoleLink = (id) => `${getAdminRolesLink()}/${id}/${app.entryView}`;
export const getAdminEditRoleLink = (id) => `${getAdminRolesLink()}/${id}/${app.entryEdit}`;

export const getReportsLink = () => app.prefix + app.reports;

export const getToolsLink = () => app.prefix + app.tools;

export const getUnauthorizedPageLink = () => app.prefix + app.unauthorizedPage;

export const getAnalyzerLink = () => `${app.prefix + app.analyzers}`;
export const getAnalyzerSessionEditLink = (id) =>
  `${app.prefix + app.analyzers}/${app.sessions}/${id}`;

export const getAnalyzerAnomalyEditLink = (id) =>
  `${app.prefix + app.analyzers}/${app.anomalies}/${id}`;
