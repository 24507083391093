"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import * as api from "../../core/api";
import { getDatatron, getDeviceById } from "../selectors/datatron.selector";
import { compareById, replaceObjectInList } from "../../../../common/helpers/object";
import { initializeDataPoint } from "../../core/common/dataPoint";
import { handleApiError } from "../../core/common/errorHandling";
import notificationMessages from "../../messages/notification.message";
import * as notification from "../../core/notifications";
import { DP_ADD_DATA_POINT } from "../constants/modals.constants";
import { highlightDataPoint } from "./datatron.devices.dataPoints.highlight.module";
import { closeModal } from "./modals.module";
import { sendNotification } from "./notifications.module";
import { createConfigKeyHashToFieldsMap } from "../../core/common/datatron.deviceType";
import { getModalPayload } from "../selectors/modals.selector";
import { validateDataPoint } from "../../core/validation/validateFieldsBaseOnSchema";
export const createDataPoint = createAction(
  "create data point (of datatron device)",
  (deviceId, newConfig) => ({ payload: { deviceId, newConfig } })
);
export const createChildDataPoint = createAction(
  "create child data point (of datatron device)",
  (deviceId, newConfig) => ({ payload: { deviceId, newConfig } })
);
export const createDataPointSuccess = createAction(
  "create data point (of datatron device) - success",
  (deviceId, newDataPoint, configKeyHashToFieldsMap) => ({
    payload: { deviceId, newDataPoint, configKeyHashToFieldsMap }
  })
);
export const createDataPointFail = createAction(
  "create data point (of datatron device) - fail",
  (deviceId, error) => ({ payload: { deviceId, error } })
);
export const clearNewDataPointError = createAction("clear the data point error field");
export const reducer = {
  [createDataPoint.type]: (state) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      newDataPoint: __spreadProps(__spreadValues({}, state.datatron.newDataPoint), {
        loading: true,
        loaded: false,
        error: null
      })
    })
  }),
  [createDataPointSuccess.type]: (state, { deviceId, newDataPoint, configKeyHashToFieldsMap }) => {
    const device = getDeviceById(state, deviceId);
    if (!device)
      return state;
    const newDevice = __spreadProps(__spreadValues({}, device), {
      configKeyHashToFieldsMap: __spreadValues(__spreadValues({}, device.configKeyHashToFieldsMap), configKeyHashToFieldsMap),
      dataPoints: __spreadProps(__spreadValues({}, device.dataPoints), {
        list: [newDataPoint, ...device.dataPoints.list]
      })
    });
    return __spreadProps(__spreadValues({}, state), {
      datatron: __spreadProps(__spreadValues({}, state.datatron), {
        devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
          list: replaceObjectInList(state.datatron.devices.list, newDevice, compareById)
        }),
        newDataPoint: __spreadProps(__spreadValues({}, state.datatron.newDataPoint), {
          loading: false,
          loaded: true,
          error: null
        })
      })
    });
  },
  [createDataPointFail.type]: (state, { error }) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      newDataPoint: __spreadProps(__spreadValues({}, state.datatron.newDataPoint), {
        loading: false,
        loaded: false,
        error
      })
    })
  }),
  [clearNewDataPointError.type]: (state) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      newDataPoint: __spreadProps(__spreadValues({}, state.datatron.newDataPoint), {
        error: null
      })
    })
  })
};
export function* createDataPointSaga({ payload: { deviceId, newConfig } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { deviceType, formType } = yield select(getModalPayload, DP_ADD_DATA_POINT);
  const validationResult = validateDataPoint(deviceType.parsedDataPointSchema, formType, newConfig);
  if (validationResult) {
    yield put(createDataPointFail(deviceId, validationResult));
    return;
  }
  const { response, error } = yield call(api.datatrons.createDataPoint, {
    datatronId: datatron.id,
    deviceId,
    payload: newConfig
  });
  if (response) {
    const dataPoint = initializeDataPoint(response);
    const configKeyHashToFieldsMap = yield call(
      createConfigKeyHashToFieldsMap,
      [dataPoint],
      deviceType.dataPointFields,
      {}
    );
    yield put(createDataPointSuccess(deviceId, dataPoint, configKeyHashToFieldsMap));
    yield put(closeModal(DP_ADD_DATA_POINT));
    yield put(highlightDataPoint(dataPoint.id));
  } else {
    const formattedError = handleApiError(error);
    yield put(closeModal(DP_ADD_DATA_POINT));
    yield put(
      sendNotification(
        notificationMessages.server_error,
        __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
          values: { error: JSON.stringify(formattedError) }
        }),
        notification.NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchCreateDataPoint() {
  yield takeEvery(createDataPoint, createDataPointSaga);
}
export function* createChildDataPointSaga({ payload: { deviceId, newConfig } }) {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { deviceType, formType } = yield select(getModalPayload, DP_ADD_DATA_POINT);
  const validationResult = validateDataPoint(deviceType.parsedDataPointSchema, formType, newConfig);
  if (validationResult) {
    yield put(createDataPointFail(deviceId, validationResult));
    return;
  }
  const { response, error } = yield call(api.datatrons.createDataPoint, {
    datatronId: datatron.id,
    deviceId,
    payload: newConfig
  });
  if (response) {
    const dataPoint = initializeDataPoint(response);
    const configKeyHashToFieldsMap = yield call(
      createConfigKeyHashToFieldsMap,
      [dataPoint],
      deviceType.dataPointFields,
      {}
    );
    yield put(createDataPointSuccess(deviceId, dataPoint, configKeyHashToFieldsMap));
    yield put(closeModal(DP_ADD_DATA_POINT));
    yield put(highlightDataPoint(dataPoint.id));
  } else {
    const formattedError = handleApiError(error);
    yield put(closeModal(DP_ADD_DATA_POINT));
    yield put(
      sendNotification(
        notificationMessages.server_error,
        __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
          values: { error: JSON.stringify(formattedError) }
        }),
        notification.NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchCreateChildDataPoint() {
  yield takeEvery(createChildDataPoint, createChildDataPointSaga);
}
