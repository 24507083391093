"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { AppReducerMapBuilder } from "./util";
import { createAction } from "@reduxjs/toolkit";
import { getAnalyzers } from "../../core/api/analyzer";
import { call, put, takeLatest } from "redux-saga/effects";
import { LOADING, succeeded, failed, IDLE } from "../../schema/fetched";
export const fetchAnalyzers = createAction("fetch analyzers");
export const fetchAnalyzersSuccess = createAction("fetch analyzers - success", (analyzers) => ({
  payload: analyzers
}));
export const fetchAnalyzersFailure = createAction("fetch analyzers - fail", (error) => ({
  payload: error
}));
export const resetAnalyzers = createAction("reset analyzers");
export const reducer = AppReducerMapBuilder.new().add(fetchAnalyzers, (state) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    main: LOADING
  })
})).add(fetchAnalyzersSuccess, (state, analyzers) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    main: succeeded(analyzers)
  })
})).add(fetchAnalyzersFailure, (state, error) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    main: failed(error)
  })
})).add(resetAnalyzers, (state) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    main: IDLE
  })
})).build();
function* fetchAnalyzersSaga() {
  try {
    const response = yield call(getAnalyzers);
    if (response.type === "generalError") {
      yield put(fetchAnalyzersFailure(response.error));
      return;
    }
    if (response.type === "parseError") {
      yield put(fetchAnalyzersFailure(response.error.message));
      return;
    }
    if (response.status == 200 && response.data) {
      yield put(fetchAnalyzersSuccess(response.data));
    }
  } catch (Err) {
    yield put(fetchAnalyzersFailure(Err));
  }
}
export function* watchFetchAnalyzers() {
  yield takeLatest(fetchAnalyzers, fetchAnalyzersSaga);
}
