"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { z } from "zod";
import { clamp } from "../components/helper/math";
import { INITIAL_SETTINGS, INITIAL_TOOL } from "./type/panelTool";
import { calculatePanelTime } from "../components/helper/panelTime";
import { formatDateWithMicrosecondsAndOffset } from "../components/helper/time";
export const INITIAL_RANGE_FROM = /* @__PURE__ */ new Date();
export const INITIAL_RANGE_DELTA_MS = 60 * 1e3;
export const MIN_RANGE_DELTA_MS = 100;
export const MIN_RANGE_STEP_MS = 100;
export const MAX_RANGE_DELTA_MS = 60 * 60 * 1e3;
export const INITIAL_COLOR = "#0f172a";
export const MIN_SIGNAL_HEIGHT = 20;
export const MAX_SIGNAL_HEIGHT = 500;
export const INITIAL_SIGNAL_HEIGHT = 60;
export const INITIAL_SIGNAL_HEIGHT_BOOL = 30;
export const INITIAL_SIGNAL_HEIGHT_STRING = 30;
export const INITIAL_LABEL_WIDTH = 300;
export const MIN_LABEL_WIDTH = 200;
const baseDateSchema = z.string().or(z.date());
export const dateSchema = baseDateSchema.transform((arg) => new Date(arg));
export const idSchema = z.number().min(1);
export const idUpdatedCreatedSchema = z.object({
  id: idSchema,
  created_at: dateSchema,
  updated_at: dateSchema
});
export const machineSchema = idUpdatedCreatedSchema.extend({
  name: z.string()
});
export const panelSchema = idUpdatedCreatedSchema.extend({
  name: z.string(),
  range_from: z.nullable(baseDateSchema).transform((arg) => arg == null ? INITIAL_RANGE_FROM : new Date(arg)),
  range_delta_ms: z.number().min(MIN_RANGE_DELTA_MS).max(MAX_RANGE_DELTA_MS).nullable().transform((arg) => arg == null ? INITIAL_RANGE_DELTA_MS : arg),
  modified_at: dateSchema,
  session_id: idSchema,
  machine_id: idSchema
});
export const anySchema = z.any();
export const sessionSchema = idUpdatedCreatedSchema.extend({
  name: z.string(),
  modified_at: dateSchema,
  owner_id: idSchema,
  alert_id: idSchema.nullable(),
  archived: z.boolean().default(false)
});
const signalTypeSchema = z.enum(["bool", "integer", "double", "string", "bytes"]);
export const signalSchema = idUpdatedCreatedSchema.extend({
  uuid: z.string().uuid(),
  name: z.string(),
  type: signalTypeSchema,
  comment: z.string().nullable(),
  machine_id: idSchema
});
export const ALERT_CLASSIFICATION_TYPES = ["ignore", "valid"];
const alertClassificationTypeSchema = z.enum(ALERT_CLASSIFICATION_TYPES);
export const isAlertClassification = (value) => ALERT_CLASSIFICATION_TYPES.includes(value);
export const alertSchema = idUpdatedCreatedSchema.extend({
  machine_id: idSchema,
  anomaly_from: dateSchema,
  reference_from: dateSchema,
  range_delta_ms: z.number().int().positive(),
  archived: z.boolean(),
  classification: alertClassificationTypeSchema.nullable()
});
export const userSchema = idUpdatedCreatedSchema.extend({
  name: z.string()
});
export const CHART_TYPES = ["line", "area"];
const chartTypeSchema = z.enum(CHART_TYPES);
export const INTERPOLATION_TYPES = [
  "basis",
  "linear",
  "monotone",
  "step",
  "stepBefore",
  "stepAfter"
];
const interpolationTypeSchema = z.enum(INTERPOLATION_TYPES);
export const panelSignalSchema = idUpdatedCreatedSchema.extend({
  color: z.string(),
  height: z.number().nullable().transform((arg) => clamp(arg != null ? arg : INITIAL_SIGNAL_HEIGHT, MIN_SIGNAL_HEIGHT, MAX_SIGNAL_HEIGHT)),
  order: z.number().nullable().transform((arg) => arg != null ? arg : 0),
  panel_id: idSchema,
  signal_id: idSchema,
  chart_type: chartTypeSchema.nullable(),
  interpolation_type: interpolationTypeSchema.nullable()
});
export const commentSchema = idUpdatedCreatedSchema.extend({
  text: z.string(),
  user_id: idSchema,
  user: userSchema
});
export const sessionUpdateNameSchema = z.object({
  name: z.string().min(1, { message: "Required" }).trim()
});
const customPartialPanelSchema = z.object({
  name: z.string(),
  machine_id: z.number(),
  range_delta_ms: z.number(),
  range_from: z.string(),
  signals: z.array(
    z.object({
      signal_id: z.number(),
      color: z.string(),
      chart_type: z.string(),
      interpolation_type: z.string(),
      order: z.number(),
      height: z.number()
    })
  ).optional()
});
export const sessionCreateBodySchema = sessionUpdateNameSchema.extend({
  datatron_uuid: z.string().uuid(),
  devices: z.array(z.string().uuid()).optional(),
  alert_id: z.number().optional(),
  panels: z.array(customPartialPanelSchema).optional()
});
export const getDefaultSignalHeight = (signalType) => {
  switch (signalType) {
    case "bool":
      return INITIAL_SIGNAL_HEIGHT_BOOL;
    case "string":
      return INITIAL_SIGNAL_HEIGHT_STRING;
    default:
      return INITIAL_SIGNAL_HEIGHT;
  }
};
export const getDefaultChartType = (signal) => {
  if (signal.type === "bool")
    return "area";
  return "line";
};
const getDefaultPanelSignalProps = (signal) => ({
  color: INITIAL_COLOR,
  interpolation_type: "stepAfter",
  height: getDefaultSignalHeight(signal.type),
  chart_type: getDefaultChartType(signal)
});
let currentNegativeId = -1;
const generateNegativeId = () => currentNegativeId--;
export const createPanelSignal = (panelId, order, signal, overrides = {}) => __spreadValues(__spreadProps(__spreadValues({
  id: generateNegativeId()
}, getDefaultPanelSignalProps(signal)), {
  signal_id: signal.id,
  panel_id: panelId,
  order,
  signal: __spreadProps(__spreadValues({}, signal), {
    panel_id: panelId
  }),
  addons: []
}), overrides);
export const createDefaultAnomalyPanels = (anomaly) => {
  const panelAnomalyNames = ["Anomaly", "Reference"];
  let count = 1;
  return panelAnomalyNames.map((panelName) => ({
    id: count++,
    name: panelName,
    settings: INITIAL_SETTINGS,
    tool: INITIAL_TOOL,
    time: panelName === "Reference" ? calculatePanelTime(new Date(anomaly.reference_from), anomaly.range_delta_ms) : calculatePanelTime(new Date(anomaly.anomaly_from), anomaly.range_delta_ms),
    range_from: panelName === "Reference" ? anomaly.reference_from : anomaly.anomaly_from,
    range_delta_ms: anomaly.range_delta_ms,
    archived: anomaly.archived,
    created_at: anomaly.created_at,
    updated_at: anomaly.updated_at,
    isPanelAnomaly: true
  }));
};
export const createAdditionalAnomalyPanel = (attr) => {
  const now = /* @__PURE__ */ new Date();
  const formateTime = formatDateWithMicrosecondsAndOffset(now);
  return __spreadValues({
    settings: INITIAL_SETTINGS,
    tool: INITIAL_TOOL,
    time: calculatePanelTime(now, MIN_RANGE_STEP_MS),
    range_from: formateTime,
    range_delta_ms: MIN_RANGE_STEP_MS,
    created_at: formateTime,
    updated_at: formateTime,
    archived: false,
    isPanelAnomaly: true
  }, attr);
};
