"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { AppReducerMapBuilder } from "./util";
import * as norm from "../../schema/normalized";
import { unixNanoToDate } from "../../components/helper/time";
import { isInRange } from "../../components/helper/panelTime";
import _ from "lodash";
import { succeeded } from "../../schema/fetched";
export const mouseMove = createAction(
  "mouse move",
  (panelId, unixNano, pos) => ({
    payload: { panelId, unixNano, pos }
  })
);
export const mouseLeave = createAction("mouse leave", (panelId) => ({
  payload: panelId
}));
export const mouseUp = createAction(
  "mouse up",
  (panelId, unixNano, pos) => ({
    payload: { panelId, unixNano, pos }
  })
);
export const mouseDown = createAction(
  "mouse down",
  (panelId, panelSignalId, unixNano, pos) => ({
    payload: { panelId, panelSignalId, unixNano, pos }
  })
);
const cursorMouseMove = (tool, newCursorUnixNano) => __spreadProps(__spreadValues({}, tool), {
  highlightUnixNano: newCursorUnixNano
});
const zoomMouseMove = (tool, newCursorUnixNano) => {
  const firstUnixNano = tool.firstUnixNano;
  const secondUnixNano = newCursorUnixNano;
  return __spreadProps(__spreadValues({}, tool), {
    firstUnixNano,
    secondUnixNano
  });
};
const cursorMouseLeave = (tool) => __spreadProps(__spreadValues({}, tool), {
  highlightUnixNano: null
});
const resetZoom = (tool) => __spreadProps(__spreadValues({}, tool), {
  firstUnixNano: null,
  secondUnixNano: null
});
const zoomMouseUp = (panel) => {
  const { firstUnixNano, secondUnixNano } = panel.tool;
  if (firstUnixNano === null)
    return null;
  if (secondUnixNano === null) {
    return __spreadProps(__spreadValues({}, panel), {
      tool: resetZoom(panel.tool)
    });
  }
  if (secondUnixNano === firstUnixNano) {
    return __spreadProps(__spreadValues({}, panel), {
      tool: resetZoom(panel.tool)
    });
  }
  return __spreadProps(__spreadValues({}, panel), {
    tool: resetZoom(panel.tool),
    range_delta_ms: Math.round(Math.abs(firstUnixNano - secondUnixNano) / 1e6),
    range_from: unixNanoToDate(Math.min(firstUnixNano, secondUnixNano))
  });
};
const zoomMouseDown = (panel, newCursorUnixNano) => __spreadProps(__spreadValues({}, panel), {
  tool: __spreadProps(__spreadValues({}, panel.tool), {
    firstUnixNano: newCursorUnixNano,
    secondUnixNano: null
  })
});
const cursorMouseDown = (panel, newCursorUnixNano) => {
  const { cursorUnixNano, deltaLeftNano, deltaRightNano } = panel.time;
  const diff = Math.abs(newCursorUnixNano - cursorUnixNano);
  if (cursorUnixNano < newCursorUnixNano) {
    if (diff > deltaRightNano)
      return null;
    return __spreadProps(__spreadValues({}, panel), {
      time: {
        cursorUnixNano: newCursorUnixNano,
        deltaLeftNano: deltaLeftNano + diff,
        deltaRightNano: deltaRightNano - diff
      }
    });
  } else {
    if (diff > deltaLeftNano)
      return null;
    return __spreadProps(__spreadValues({}, panel), {
      time: {
        cursorUnixNano: newCursorUnixNano,
        deltaLeftNano: deltaLeftNano - diff,
        deltaRightNano: deltaRightNano + diff
      }
    });
  }
};
const bitRulerMouseDown = (panel, panelSignals, panelSignalId, unixNano, leftOfUnixNano) => {
  var _a;
  unixNano = leftOfUnixNano ? Math.max(unixNano - 1e3, 0) : unixNano;
  const panelSignal = panelSignals.byId[panelSignalId].data;
  if (!panelSignal)
    return null;
  if (panel.tool.type !== "bitRuler")
    return null;
  if (panelSignal.signal.type !== "bool")
    return null;
  if (!isInRange(panel.time, unixNano))
    return null;
  const existingBitRulerBox = panelSignal.addons.find((a) => {
    if (a.type !== "bitRulerBox")
      return false;
    if (a.unixNano === unixNano)
      return true;
  });
  if (existingBitRulerBox) {
    return __spreadValues({}, norm.update(
      panelSignals,
      panelSignalId,
      succeeded(__spreadProps(__spreadValues({}, panelSignal), {
        addons: panelSignal.addons.filter((a) => a.unixNano !== existingBitRulerBox.unixNano)
      }))
    ));
  }
  const id = ((_a = _(panelSignal.addons).map("id").max()) != null ? _a : 0) + 1;
  return __spreadValues({}, norm.update(
    panelSignals,
    panelSignalId,
    succeeded(__spreadProps(__spreadValues({}, panelSignal), {
      addons: [...panelSignal.addons, { id, type: "bitRulerBox", unixNano }]
    }))
  ));
};
export const reducer = AppReducerMapBuilder.new().add(mouseLeave, (state, panelId) => {
  const panel = state.analyzer.panels.byId[panelId].data;
  let tool = panel.tool;
  if (panel.tool.type === "cursor") {
    tool = cursorMouseLeave(tool);
  } else {
    return state;
  }
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: __spreadValues({}, norm.update(state.analyzer.panels, panelId, {
        data: __spreadProps(__spreadValues({}, panel), {
          tool
        })
      }))
    })
  });
}).add(mouseMove, (state, { panelId, unixNano }) => {
  const panel = state.analyzer.panels.byId[panelId].data;
  let tool = panel.tool;
  if (panel.tool.type === "cursor") {
    tool = cursorMouseMove(tool, unixNano);
  } else if (panel.tool.type === "zoom") {
    tool = zoomMouseMove(tool, unixNano);
  } else {
    return state;
  }
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: __spreadValues({}, norm.update(state.analyzer.panels, panelId, {
        data: __spreadProps(__spreadValues({}, panel), {
          tool
        })
      }))
    })
  });
}).add(mouseUp, (state, { panelId }) => {
  const panel = state.analyzer.panels.byId[panelId].data;
  let newPanel = panel;
  if (panel.tool.type === "zoom") {
    newPanel = zoomMouseUp(panel);
  } else {
    return state;
  }
  if (newPanel === null)
    return state;
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: __spreadValues({}, norm.update(state.analyzer.panels, panelId, {
        data: newPanel
      }))
    })
  });
}).add(mouseDown, (state, { panelId, panelSignalId, unixNano, pos }) => {
  const panel = state.analyzer.panels.byId[panelId].data;
  const panelSignals = state.analyzer.panelSignals;
  let newPanel = panel;
  let newPanelSignals = panelSignals;
  if (panel.tool.type === "zoom") {
    newPanel = zoomMouseDown(panel, unixNano);
  } else if (panel.tool.type === "cursor") {
    newPanel = cursorMouseDown(panel, unixNano);
  } else if (panel.tool.type === "bitRuler") {
    newPanelSignals = bitRulerMouseDown(
      panel,
      panelSignals,
      panelSignalId,
      unixNano,
      pos === "left"
    );
  } else {
    return state;
  }
  if (newPanelSignals === null)
    return state;
  if (newPanel === null)
    return state;
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: __spreadValues({}, norm.update(state.analyzer.panels, panelId, {
        data: newPanel
      })),
      panelSignals: newPanelSignals
    })
  });
}).build();
