/** A constant for time format in hours and minutes. */
export const timeFormat = 'HH:mm';

/** A constant for datetime format in RFC2445 standard. */
export const DATE_TIME_RFC2445_FORMAT = 'YYYYMMDDTHHmmss';

/** A constant to represent ascending sort order. */
export const SORT_WAY_ASC = 'ASC';

/** A constant to represent descending sort order. */
export const SORT_WAY_DESC = 'DESC';

/** A constant to represent the date from. */
export const DATE_FROM = 'DATE_FROM';

/** A constant to represent the date to. */
export const DATE_TO = 'DATE_TO';