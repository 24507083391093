"use strict";
import { anySchema } from "../../schema/analyzeSessions";
import { apiRequestWithSchema, postApiRequest, deleteApiRequest, putApiRequest } from "./_tools";
export const getAnalyzers = () => apiRequestWithSchema("/api/analyzers", anySchema);
export const addNewSession = (data) => postApiRequest(`/api/sessions`, data);
export const updateSession = (sessionId, data) => putApiRequest(`/api/sessions/${sessionId}`, data);
export const archiveSession = (sessionId) => postApiRequest(`/api/sessions/${sessionId}/archive`, {});
export const archiveAnomaly = (anomalyId) => postApiRequest(`/api/anomalies/${anomalyId}/archive`, {});
export const deleteSession = (sessionId) => deleteApiRequest(`/api/sessions/${sessionId}`);
export const addNewPanel = (sessionId, data) => postApiRequest(`/api/sessions/${sessionId}/panels`, data);
export const updatePanel = (sessionId, panelId, data) => putApiRequest(`/api/sessions/${sessionId}/panels/${panelId}`, data);
export const deletePanel = (sessionId, panelId) => deleteApiRequest(`/api/sessions/${sessionId}/panels/${panelId}`);
export const getSessionById = (sessionId) => apiRequestWithSchema(`/api/sessions/${sessionId}`, anySchema);
export const getAnomalyById = (anomalyId) => apiRequestWithSchema(`/api/anomalies/${anomalyId}`, anySchema);
export const getPanelSignals = (sessionId, panelId) => apiRequestWithSchema(`/api/sessions/${sessionId}/panels/${panelId}/signals`, anySchema);
export const getSignals = (machineId = 1) => apiRequestWithSchema(`/api/sessions/${machineId}/signals`, anySchema);
export const getSignalData = (sessionId, signalUuid, startTime, endTime) => apiRequestWithSchema(
  `/api/sessions/${sessionId}/signals/${signalUuid}?start_time=${startTime}&end_time=${endTime}`,
  anySchema
);
