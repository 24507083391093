import React from 'react';
import { SessionLocation } from './SessionLocation';
import Loader from '../../_tools/loader/Loader';
import { useSelector } from 'react-redux';
import { selectLocationFilter } from '../../../redux/selectors/location.selector';
import { ErrorPlaceholder } from '../../inventory/list/ErrorPlaceholder';
import EmptyPlaceholder from '../../../components/modals/tools/viewToolModal/form/history/EmptyPlaceholder.jsx';
import {
  selectAnalyzerError,
  getSessionsLocation,
  analyzerLoading,
  isAnalyzerEmpty,
} from '../../../redux/selectors/analyzer.selector';

type LocationType = {
  id: string;
  name: string;
  path: string[];
};

export const AnalyzerList: React.FC = () => {
  const locations = useSelector(getSessionsLocation);
  const isLoading = useSelector(analyzerLoading);
  const error = useSelector(selectAnalyzerError);
  const locationFilter = useSelector(selectLocationFilter);

  const filteredLocations = locations
    ? locations.filter(
        (loc: LocationType) =>
          !locationFilter || locationFilter === loc.name || loc.path.indexOf(locationFilter) > -1,
      )
    : [];

  const isEmpty = useSelector(isAnalyzerEmpty);
  const emptyPlaceholder = !!error ? <ErrorPlaceholder /> : <EmptyPlaceholder />;

  return (
    <div>
      <div className='analyzer-blocks'>
        {filteredLocations &&
          filteredLocations.map((location) => (
            <SessionLocation location={location} key={location.id} />
          ))}
      </div>
      <Loader show={isLoading} />
      {isEmpty && emptyPlaceholder}
    </div>
  );
};
