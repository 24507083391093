import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

type TooltipPosition = 'top' | 'bottom' | 'left' | 'right';

interface TooltipProps {
  data: Record<string, string | number>;
  position?: TooltipPosition;
  delay?: number;
  children: React.ReactNode;
  className?: string;
}

const calculatePosition = (
  element: HTMLElement | null,
  tooltip: HTMLElement | null,
  position: TooltipPosition,
) => {
  if (!element || !tooltip) {
    return { x: 0, y: 0 };
  }

  const rect = element.getBoundingClientRect();
  const tooltipRect = tooltip.getBoundingClientRect();
  const spacing = 5;

  let x = 0;
  let y = 0;

  switch (position) {
    case 'top':
      x = rect.left + (rect.width - tooltipRect.width) / 2;
      y = rect.top - tooltipRect.height - spacing;
      break;
    case 'bottom':
      x = rect.left + (rect.width - tooltipRect.width) / 2;
      y = rect.bottom + spacing;
      break;
    case 'left':
      x = rect.left - tooltipRect.width - spacing;
      y = rect.top + (rect.height - tooltipRect.height) / 2;
      break;
    case 'right':
      x = rect.right + spacing;
      y = rect.top + (rect.height - tooltipRect.height) / 2;
      break;
    default:
      x = rect.right + spacing;
      y = rect.top + (rect.height - tooltipRect.height) / 2;
      break;
  }

  x = Math.max(spacing, Math.min(x, window.innerWidth - tooltipRect.width - spacing));
  y = Math.max(spacing, Math.min(y, window.innerHeight - tooltipRect.height - spacing));

  return { x, y };
};

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

interface TooltipTipProps {
  $position: TooltipPosition;
  $visible: boolean;
  $x: number;
  $y: number;
}

const TooltipTip = styled.div<TooltipTipProps>`
  position: fixed;
  background-color: white;
  color: #1f2937;
  padding: 10px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.4;
  z-index: 100;
  white-space: nowrap;
  pointer-events: none;
  transition: opacity 0.2s ease;
  opacity: ${(props) => (props.$visible ? 1 : 0)};
  left: ${(props) => props.$x}px;
  top: ${(props) => props.$y}px;
  border: 1px solid #e5e7eb;
`;

export const Tooltip: React.FC<TooltipProps> = ({
  data,
  position = 'top',
  delay = 200,
  children,
  className,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const showTooltip = () => {
    timeoutRef.current = setTimeout(() => {
      if (wrapperRef.current && tooltipRef.current) {
        const coords = calculatePosition(wrapperRef.current, tooltipRef.current, position);
        setCoordinates(coords);
        setIsVisible(true);
      }
    }, delay);
  };

  const hideTooltip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsVisible(false);
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  return (
    <TooltipWrapper
      ref={wrapperRef}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      className={className}
    >
      {children}
      <TooltipTip
        ref={tooltipRef}
        $position={position}
        $visible={isVisible}
        $x={coordinates.x}
        $y={coordinates.y}
      >
        <div>
          {Object.entries(data).map(([key, value]) => (
            <div key={key} style={{ display: 'flex' }}>
              <strong style={{ marginRight: '8px' }}>{key}:</strong>
              <span>{value}</span>
            </div>
          ))}
        </div>
      </TooltipTip>
    </TooltipWrapper>
  );
};
