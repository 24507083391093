import { defineMessages } from 'react-intl';

export default defineMessages({
  showMachines: {
    id: 'widgets.reportForm.show_all_n_machines',
    defaultMessage: 'Show {count} machines',
  },
  searchResultsForQuery: {
    id: 'widgets.reportForm.search_results_for',
    defaultMessage: 'Search results for {query}',
  },
  selectedMachines: {
    id: 'widgets.reportForm.selectedMachines',
    defaultMessage: 'Selected Machines',
  },
  archivedMachinesWillBeRemoved: {
    id: 'widgets.reportForm.archived_machines_will_be_removed',
    defaultMessage: 'Archived Machines will be removed from this list',
  },
  selectedDataPoints: {
    id: 'widgets.reportForm.selectedDataPoints',
    defaultMessage: 'Selected Data Points',
  },
  archivedDataPointsWillBeRemoved: {
    id: 'widgets.reportForm.archived_data_points_will_be_removed',
    defaultMessage: 'Archived Data Points will be removed from this list',
  },
});
