import { debounce } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 4px;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background-color: white;
  width: fit-content;
  height: 37px;
`;

const TimeInput = styled.input`
  border: none;
  width: 18px;
  height: 100%;
  text-align: center;
  font-size: 12px;
  background: transparent;
  padding: 0;
  box-sizing: border-box;

  &:disabled {
    color: #666;
    background: transparent;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.milliseconds {
    width: 25px;
  }
`;

const Separator = styled.span`
  color: #333;
  margin: 0 1px;
  display: inline-block;
`;
const TIME_CONSTANTS = {
  MS_PER_DAY: 86400000, // 24 * 60 * 60 * 1000
  DEBOUNCE_DELAY: 500,
  TIME_MULTIPLIERS: {
    HOURS: 3600000, // 60 * 60 * 1000
    MINUTES: 60000, // 60 * 1000
    SECONDS: 1000,
    MILLISECONDS: 1,
  },
  PAD_LENGTHS: {
    STANDARD: 2,
    MILLISECONDS: 3,
  },
} as const;

type TimeUnit = 'minutes' | 'seconds' | 'milliseconds';

const normalizeTime = (totalMilliseconds: number, currentTime: Date): Date => {
  const normalizedMs =
    ((totalMilliseconds % TIME_CONSTANTS.MS_PER_DAY) + TIME_CONSTANTS.MS_PER_DAY) %
    TIME_CONSTANTS.MS_PER_DAY;
  const newTime = new Date(currentTime);
  newTime.setHours(0, 0, 0, normalizedMs);
  return newTime;
};

const calculateCurrentMs = (time: Date): number =>
  time.getHours() * TIME_CONSTANTS.TIME_MULTIPLIERS.HOURS +
  time.getMinutes() * TIME_CONSTANTS.TIME_MULTIPLIERS.MINUTES +
  time.getSeconds() * TIME_CONSTANTS.TIME_MULTIPLIERS.SECONDS +
  time.getMilliseconds();

interface CursorTimeProps {
  time: Date;
  onChange: (date: Date) => void;
}

export const CursorTime: React.FC<CursorTimeProps> = React.memo(
  ({ time: currentTime, onChange }) => {
    const [time, setTime] = useState<Date>(currentTime);

    useEffect(() => {
      setTime(currentTime);
    }, [currentTime]);

    const debouncedOnChange = useMemo(
      () =>
        debounce((newTime: Date) => onChange(newTime), TIME_CONSTANTS.DEBOUNCE_DELAY, {
          leading: false,
          trailing: true,
        }),
      [onChange],
    );

    const updateTimeValue = useCallback(
      (newTime: Date) => {
        setTime(newTime);
        debouncedOnChange(newTime);
      },
      [debouncedOnChange],
    );

    const adjustTime = useCallback(
      (unit: TimeUnit, value: number) => {
        const currentMs = calculateCurrentMs(time);
        const multiplier =
          TIME_CONSTANTS.TIME_MULTIPLIERS[
            unit.toUpperCase() as keyof typeof TIME_CONSTANTS.TIME_MULTIPLIERS
          ];
        const newTime = normalizeTime(currentMs + value * multiplier, time);
        updateTimeValue(newTime);
      },
      [time, updateTimeValue],
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>, unit: TimeUnit) => {
        const isArrowUp = e.key === 'ArrowUp';
        const isArrowDown = e.key === 'ArrowDown';

        if (isArrowUp || isArrowDown) {
          e.preventDefault();
          adjustTime(unit, isArrowUp ? 1 : -1);
        }
      },
      [adjustTime],
    );

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, unit: TimeUnit) => {
        const value = e.target.value;
        if (!value) return;

        const numValue = parseInt(value, 10);
        if (isNaN(numValue)) return;

        const currentValue = time[`get${unit.charAt(0).toUpperCase() + unit.slice(1)}`]();
        adjustTime(unit, numValue - currentValue);
      },
      [time, adjustTime],
    );

    return (
      <TimeContainer>
        <TimeInput type='text' value={time.getHours().toString().padStart(2, '0')} disabled />
        <Separator>:</Separator>
        <TimeInput
          type='text'
          value={time.getMinutes().toString().padStart(2, '0')}
          onChange={(e) => handleInputChange(e, 'minutes')}
          onKeyDown={(e) => handleKeyDown(e, 'minutes')}
        />
        <Separator>:</Separator>
        <TimeInput
          type='text'
          value={time.getSeconds().toString().padStart(2, '0')}
          onChange={(e) => handleInputChange(e, 'seconds')}
          onKeyDown={(e) => handleKeyDown(e, 'seconds')}
        />
        <Separator>.</Separator>
        <TimeInput
          className='milliseconds'
          type='text'
          value={time.getMilliseconds().toString().padStart(3, '0')}
          onChange={(e) => handleInputChange(e, 'milliseconds')}
          onKeyDown={(e) => handleKeyDown(e, 'milliseconds')}
        />
      </TimeContainer>
    );
  },
);
