import React, { useCallback } from 'react';
import {
  getPanelById,
  getSignals,
  getSignalsByPanelId,
  getTimeByPanelId,
} from '../../../redux/selectors/analyzer.selector';
import { useDispatch, useSelector } from 'react-redux';
import { AddSignalInput, Item } from './AddSignalInput';
import {
  updateSessionPanelAddSignal,
  updateSessionPanelPartial,
} from '../../../redux/modules/analyzer.sessions.panel.update.module';
import { XAxisChart } from '../signalChart/XAxisChart';
import { CursorTime } from '../../_tools/dateTimePicker/CursorTime';
import {
  dateToUnixNano,
  formatDateWithMicrosecondsAndOffset,
  unixNanoToDate,
} from '../../helper/time';

export const PanelFooter: React.FC<{ panelId: number }> = React.memo(({ panelId }) => {
  const dispatch = useDispatch();
  const signals = useSelector((state) => getSignals(state));
  const panel = useSelector((state) => getPanelById(state, panelId));
  const panelTime = useSelector((state) => getTimeByPanelId(state, panelId));
  const cursorTime = unixNanoToDate(panelTime.cursorUnixNano);
  const selectedDate = panel?.range_from ? new Date(panel.range_from) : new Date();
  const panelSignals = useSelector((state) => getSignalsByPanelId(state, panelId));
  const mappedPanelSignals = panelSignals.map((signal) => ({
    id: signal.signal.id,
    label: signal.signal.name,
  }));

  const handleAddSignal = useCallback(
    (selectedItem: Item | Item[]) => {
      dispatch(
        updateSessionPanelAddSignal(panelId, {
          signalId: (selectedItem as Item).id,
        }),
      );
    },
    [dispatch, panelId],
  );

  const handCursorTimeChange = (newTime: Date) => {
    const newDate = new Date(selectedDate.getTime() + (newTime.getTime() - cursorTime.getTime()));
    dispatch(
      updateSessionPanelPartial(panelId, {
        range_from: formatDateWithMicrosecondsAndOffset(newDate),
        status: 'updated',
        time: {
          ...panelTime,
          cursorUnixNano: dateToUnixNano(newTime),
        },
      }),
    );
  };

  return (
    <>
      <div className='row-signal-footer col--1'>
        <div className='row-signal-input  add-signal-input'>
          {signals && (
            <AddSignalInput
              items={signals}
              selectedItems={mappedPanelSignals}
              onChange={handleAddSignal}
              placeholder='Add Signal'
            ></AddSignalInput>
          )}
        </div>
        <div className='row-signal-chart'>
          <XAxisChart panelId={panelId} />
        </div>
        <div className='cursor-time-container'>
          <CursorTime
            time={cursorTime}
            onChange={handCursorTimeChange}
            key={`${panelId}-cursor-time`}
          />
        </div>
      </div>
    </>
  );
});
