import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PanelSignalContextMenu as BasePanelSignalContextMenu } from '../ContextMenu';
import { ChartType, InterpolationType } from '../../../schema/analyzeSessions';
import { getPanelSettingsByPanelId } from '../../../redux/selectors/analyzer.selector';
import {
  removeSessionPanelSignal,
  updateSessionPanelPartial,
  updateSessionPanelSignal,
} from '../../../redux/modules/analyzer.sessions.panel.update.module';
import { PanelSettings } from '../../../schema/type/panel';
import { Todo } from '../../../../../common/types/common';

interface Props {
  children: React.ReactNode;
  panelId: number;
  panelSignal: Todo;
}

export const PanelSignalContextMenu: React.FC<Props> = ({ panelSignal, panelId, children }) => {
  const dispatch = useDispatch();

  const removeSignal = useCallback(() => {
    dispatch(removeSessionPanelSignal(panelSignal.id));
  }, [dispatch, panelSignal.id]);

  const setColor = useCallback(
    (color: string) => {
      dispatch(updateSessionPanelSignal(panelId, panelSignal.id, { color }));
    },
    [dispatch, panelId, panelSignal.id],
  );

  const setInterpolationType = useCallback(
    (interpolationType: InterpolationType) => {
      dispatch(
        updateSessionPanelSignal(panelId, panelSignal.id, {
          interpolation_type: interpolationType,
        }),
      );
    },
    [dispatch, panelId, panelSignal.id],
  );

  const setChartType = useCallback(
    (chartType: ChartType) => {
      dispatch(
        updateSessionPanelSignal(panelId, panelSignal.id, {
          chart_type: chartType,
        }),
      );
    },
    [dispatch, panelId, panelSignal.id],
  );

  const setPanelSettings = useCallback(
    (panelSettings: PanelSettings) => {
      dispatch(
        updateSessionPanelPartial(panelId, {
          settings: panelSettings,
        }),
      );
    },
    [dispatch, panelId],
  );
  const panelSettings = useSelector((state) => getPanelSettingsByPanelId(state, panelId));

  return (
    <BasePanelSignalContextMenu
      chartType={panelSignal.chart_type}
      setChartType={setChartType}
      color={panelSignal.color}
      setColor={setColor}
      interpolationType={panelSignal.interpolation_type}
      setInterpolationType={setInterpolationType}
      panelSettings={panelSettings}
      setPanelSettings={setPanelSettings}
      removeSignal={removeSignal}
      signalType={panelSignal.signal.type}
    >
      {children}
    </BasePanelSignalContextMenu>
  );
};
