"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import { startSubmit, stopSubmit } from "redux-form";
import { closeModal } from "./modals.module";
import { sendNotification } from "./notifications.module";
import { AppReducerMapBuilder } from "./util";
import { ANALYZER_SESSION_EDIT } from "../constants/modals.constants";
import { ANALYZER_SESSION_FORM } from "../constants/analyzer.form.constants";
import notificationMessage from "../../messages/notification.message";
import notificationMessages from "../../messages/notification.message";
import * as norm from "../../schema/normalized";
import { succeeded } from "../../schema/fetched";
import { sessionUpdateNameSchema } from "../../schema/analyzeSessions";
import { updateSession as updateSessionAPI } from "../../core/api/analyzer";
import { NOTIFICATION_EVENT, NOTIFICATION_ERROR } from "../../core/notifications";
import { validateData } from "../../core/validation/validateFieldsBaseOnSchema";
export const updateSession = createAction("update session", (sessionId, name) => ({
  payload: { sessionId, name }
}));
export const updateSessionSuccess = createAction("update session success", (session) => ({
  payload: session
}));
export const reducer = AppReducerMapBuilder.new().add(updateSessionSuccess, (state, session) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    sessions: norm.upsert(state.analyzer.sessions, session.id, succeeded(__spreadValues({}, session)))
  })
})).build();
export function* updateSessionSaga({ payload: { sessionId, name } }) {
  yield put(startSubmit(ANALYZER_SESSION_FORM));
  const validationResult = validateData(sessionUpdateNameSchema, {
    name
  });
  if (validationResult) {
    yield put(stopSubmit(ANALYZER_SESSION_FORM, validationResult));
    return;
  }
  const { response, error } = yield call(updateSessionAPI, sessionId, { name });
  yield put(stopSubmit(ANALYZER_SESSION_FORM));
  yield put(closeModal(ANALYZER_SESSION_EDIT));
  if (response) {
    yield put(updateSessionSuccess(response));
    yield put(
      sendNotification(
        notificationMessage.successfully_changed_event,
        notificationMessage.successfully_changed_event_description,
        NOTIFICATION_EVENT
      )
    );
  } else {
    yield put(
      sendNotification(
        __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
          values: { error }
        }),
        null,
        NOTIFICATION_ERROR
      )
    );
  }
}
export function* watchUpdateSession() {
  yield takeLatest(updateSession, updateSessionSaga);
}
