import React from 'react';
import PropTypes from 'prop-types';

const emptyFunc = () => {};
const handleMouseDown = (e) => e.preventDefault();

export const InputButtonWithIcon = ({
  items, onClick, iconClassName, useButtonTag, tabIndex,
}) => {
  const label = items.map((item) => item.name).join(', ');
  return (
    <div className="search-block">
      <div className="search-text" onClick={onClick} onMouseDown={handleMouseDown}>
        { useButtonTag ?
          (<button type="button" className="search-input" tabIndex={tabIndex}>{label}</button>) :
          (<input type="text" className="search-input" value={label} onChange={emptyFunc} tabIndex={tabIndex} />)}
        <div className="input-group-button">
          <button type="button" className="button__search">
            <span className={iconClassName} />
          </button>
        </div>
      </div>
    </div>
  );
};

InputButtonWithIcon.propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  iconClassName: PropTypes.string.isRequired,
  useButtonTag: PropTypes.bool,
  tabIndex: PropTypes.number,
};

InputButtonWithIcon.defaultProps = {
  useButtonTag: false,
};

export default InputButtonWithIcon;
