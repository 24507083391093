export const NAME_FILTER_INPUT = 'NAME_FILTER_INPUT';

export const AP_ORGANIZATIONS_NAME_FILTER_INPUT = 'AP_ORGANIZATIONS_NAME_FILTER_INPUT';
export const AP_LOCATIONS_NAME_FILTER_INPUT = 'AP_LOCATIONS_NAME_FILTER_INPUT';
export const AP_USERS_NAME_FILTER_INPUT = 'AP_USERS_NAME_FILTER_INPUT';
export const AP_USERS_EMAIL_FILTER_INPUT = 'AP_USERS_EMAIL_FILTER_INPUT';

export const TOOLS_PAGE_QUERY_FILTER_INPUT = 'TOOLS_PAGE_QUERY_FILTER_INPUT';

export const EXPERIMENTS_QUERY_FILTER_INPUT = 'EXPERIMENTS_QUERY_FILTER_INPUT';

export const DEFAULT_FILTER_INPUT = NAME_FILTER_INPUT;
