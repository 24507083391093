import React, { useEffect, useRef, useState } from 'react';
import { SignalChart } from '../signalChart/SignalChart';
import {
  draggable,
  dropTargetForElements,
} from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
export { monitorForElements } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
  attachClosestEdge,
  Edge,
  extractClosestEdge,
} from '@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import invariant from 'tiny-invariant';
import { SignalInfo } from '../signalChart/SignalInfo';
import { DropIndicatorWrapper } from './DropIndicatorWrapper';
import { CursorLabel } from '../signalChart/CursorLabel';
import { useSignalData } from './UseSignalDataHook';

const DragIcon: React.FC = () => (
  <div className='drag-handle'>
    <div className='draggable-icon' />
  </div>
);

export const RowSignal: React.FC<{
  panelId: number;
  panelSignal: any;
  index: number;
  id: number;
}> = React.memo(({ panelId, panelSignal, index, id }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const dragHandleRef = useRef<HTMLDivElement | null>(null);
  const [closestEdge, setClosestEdge] = useState<Edge | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  useEffect(() => {
    invariant(ref.current);
    invariant(dragHandleRef.current);
    const element = ref.current;
    const dragHandle = dragHandleRef.current;

    return combine(
      draggable({
        element,
        dragHandle,
        getInitialData: () => ({ id, index, panelId, uniqueKey: `${panelId}-${index}` }),
        onDragStart: () => setIsDragging(true),
        onDrop: () => setIsDragging(false),
      }),
      dropTargetForElements({
        element,
        getData: ({ input }) =>
          attachClosestEdge({ id, index }, { input, element, allowedEdges: ['top', 'bottom'] }),
        getIsSticky: () => true,
        canDrop: (args) => {
          if (args.source.data.id === id) return false;
          if (args.source.data.panelId !== panelId) return false;
          return true;
        },
        onDrag: ({ self, source, location }) => {
          if (element === source.element) {
            setClosestEdge(null);
            return;
          }

          const currentDropTarget = location.current.dropTargets[0];
          if (!currentDropTarget) return;

          const selfIndex = self.data.index;
          const sourceIndex = source.data.index;
          if (typeof sourceIndex !== 'number') return;

          const isItemBeforeSource = selfIndex === sourceIndex - 1;
          const isItemAfterSource = selfIndex === sourceIndex + 1;
          const nearestEdge = extractClosestEdge(currentDropTarget.data);

          const isDropIndicatorHidden =
            (isItemBeforeSource && nearestEdge === 'bottom') ||
            (isItemAfterSource && nearestEdge === 'top');

          if (isDropIndicatorHidden) {
            setClosestEdge(null);
            return;
          }

          if (currentDropTarget.data.id === id) {
            setClosestEdge(nearestEdge);
          }
        },
        onDragLeave: () => setClosestEdge(null),
        onDrop: () => setClosestEdge(null),
      }),
    );
  }, [id, index, panelId]);

  const { signal, ...panelSignalProps } = panelSignal;
  const { signalData, isLoading, error, cursorValue, fromUnixNano, toUnixNano } = useSignalData(
    panelId,
    signal.uuid,
  );
  return (
    <div ref={ref} style={{ position: 'relative', height: '100%' }}>
      <div
        className='row-signal col--1'
        style={{
          opacity: isDragging ? 0.7 : 1,
          position: 'relative',
        }}
      >
        <div ref={dragHandleRef}>
          <DragIcon />
        </div>
        <SignalInfo signal={panelSignal.signal} />
        <SignalChart
          panelId={panelId}
          panelSignalProps={{ ...panelSignalProps, type: signal.type }}
          signalData={signalData}
          isLoading={isLoading}
          error={error}
          fromUnixNano={fromUnixNano}
          toUnixNano={toUnixNano}
        />
        <CursorLabel cursorValue={cursorValue} />
      </div>
      {closestEdge && <DropIndicatorWrapper edge={closestEdge} />}
    </div>
  );
});
