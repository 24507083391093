"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { clamp } from "lodash";
import { dateToUnixNano, unixNanoToDate } from "./time";
import { MAX_RANGE_DELTA_MS, MIN_RANGE_DELTA_MS } from "../../schema/analyzeSessions";
export const calculatePanelTime = (rangeFrom, rangeDeltaMs, cursorUnixNano) => {
  const fromUnixNano = dateToUnixNano(rangeFrom);
  const toUnixNano = fromUnixNano + rangeDeltaMs * 1e6;
  let cun = cursorUnixNano != null ? cursorUnixNano : Math.floor((fromUnixNano + toUnixNano) / 2);
  cun = clamp(cun, fromUnixNano, toUnixNano);
  return {
    cursorUnixNano: cun,
    deltaLeftNano: cun - fromUnixNano,
    deltaRightNano: toUnixNano - cun
  };
};
export const calculateFromDate = (time) => {
  const fromUnixNano = time.cursorUnixNano - time.deltaLeftNano;
  return unixNanoToDate(fromUnixNano);
};
export const calculateDeltaMs = (time) => {
  const diffNano = time.deltaLeftNano + time.deltaRightNano;
  return Math.floor(diffNano / 1e6);
};
export const isInRange = (panelTime, unixNano) => {
  const fromUnixNano = panelTime.cursorUnixNano - panelTime.deltaLeftNano;
  const toUnixNano = panelTime.cursorUnixNano + panelTime.deltaRightNano;
  return unixNano >= fromUnixNano && unixNano <= toUnixNano;
};
export const withNewDelta = (panelTime, newDeltaMs) => {
  const clampedNewDeltaMs = clamp(newDeltaMs, MIN_RANGE_DELTA_MS, MAX_RANGE_DELTA_MS);
  const currDeltaMs = calculateDeltaMs(panelTime);
  if (clampedNewDeltaMs === currDeltaMs)
    return panelTime;
  const { deltaLeftNano, deltaRightNano } = panelTime;
  const leftRatio = deltaLeftNano / (deltaLeftNano + deltaRightNano);
  const newDeltaNano = clampedNewDeltaMs * 1e6;
  const newDeltaLeftNano = Math.floor(newDeltaNano * leftRatio);
  const newDeltaRightNano = newDeltaNano - newDeltaLeftNano;
  return __spreadProps(__spreadValues({}, panelTime), {
    deltaLeftNano: newDeltaLeftNano,
    deltaRightNano: newDeltaRightNano
  });
};
export const withNewFromDate = (panelTime, newFromDate) => {
  const currFromDate = calculateFromDate(panelTime);
  if (newFromDate.getTime() === currFromDate.getTime())
    return panelTime;
  const unixNanoDiff = (currFromDate.getTime() - newFromDate.getTime()) * 1e6;
  return __spreadProps(__spreadValues({}, panelTime), {
    cursorUnixNano: panelTime.cursorUnixNano - unixNanoDiff
  });
};
export const withNewCursorUnixNano = (panelTime, newCursorUnixNano) => {
  const { deltaLeftNano, cursorUnixNano } = panelTime;
  const clamped = clamp(newCursorUnixNano, deltaLeftNano, Infinity);
  if (clamped === cursorUnixNano)
    return panelTime;
  return __spreadProps(__spreadValues({}, panelTime), {
    cursorUnixNano: clamped
  });
};
