import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PanelActions } from './PanelActions';
import { DateTimePickerComponent } from '../../_tools/dateTimePicker/DateTimePickerComponent';
import { TimeRangeInput } from '../../_tools/input/InputRange';
import { getPanelById, getPanelStatus } from '../../../redux/selectors/analyzer.selector';
import { updateSessionPanelPartial } from '../../../redux/modules/analyzer.sessions.panel.update.module';
import { ToolToggleGroup } from './ToolToggleGroup';
import { calculateFromDate, withNewDelta, withNewFromDate } from '../../helper/panelTime';
import { formatDateWithMicrosecondsAndOffset } from '../../helper/time';

export const PanelHeader: React.FC<{ panelId: number }> = React.memo(({ panelId }) => {
  const dispatch = useDispatch();
  const panel = useSelector((state) => getPanelById(state, panelId));
  const panelStatus = useSelector((state) => getPanelStatus(state, panelId));

  const handleDateChange = (newDate: Date) => {
    const rangeFrom = formatDateWithMicrosecondsAndOffset(newDate);
    const time = withNewFromDate(panel.time, newDate);

    dispatch(
      updateSessionPanelPartial(panelId, {
        range_from: rangeFrom,
        time,
      }),
    );
  };

  const selectedDate = useMemo(() => {
    if (panel && panel.range_from) {
      return new Date(panel.range_from);
    }
    return new Date();
  }, [panel]);

  const handleRangeChange = (newRange: number) => {
    const time = withNewDelta(panel.time, newRange);
    const rangeFrom = calculateFromDate(time);

    dispatch(
      updateSessionPanelPartial(panelId, {
        range_from: rangeFrom,
        range_delta_ms: newRange,
        time,
      }),
    );
  };

  const handelToggleTool = (panelTool) => {
    dispatch(
      updateSessionPanelPartial(panelId, {
        tool: panelTool,
      }),
    );
  };

  return (
    <>
      <div>{panel.name}</div>
      <div className='action-panel-session'>
        <ToolToggleGroup panel={panel} onChange={handelToggleTool} />
        <PanelActions panel={panel} status={panelStatus}></PanelActions>
        <DateTimePickerComponent
          selectedDate={selectedDate}
          onDateChange={handleDateChange}
          className='datepicker-input-session-panel'
        />
        <TimeRangeInput initialValue={panel.range_delta_ms} onChange={handleRangeChange} />
      </div>
    </>
  );
});
