import React from 'react';
import { useDispatch } from 'react-redux';

import { injectIntl, WrappedComponentProps } from 'react-intl';
import TabsBar from '../../common/tabsBar/TabsBar';
import commonMessages from '../../../messages/common.messages';
import { openModal } from '../../../redux/modules/modals.module';
import {
  ANALYZER_SESSION_COMMAND,
  ANALYZER_SESSION_EDIT,
  ANALYZER_SESSION_PANEL_ADD,
} from '../../../redux/constants/modals.constants';
import { TabCommands } from '../../../schema/type/session';

interface SessionActionsProps extends WrappedComponentProps {
  session: {
    name: string;
    id: number;
    datatron_uuid: string;
    devices: string[];
  };
}

export const SessionActions: React.FC<SessionActionsProps> = ({ intl, session }) => {
  const dispatch = useDispatch();

  const mainButtons = [
    {
      title: session ? session.name : '',
      isActive: true,
      onClick: () =>
        dispatch(
          openModal(ANALYZER_SESSION_EDIT, {
            id: session.id,
            name: session.name,
            datatron_uuid: session.datatron_uuid,
            devices: session.devices,
          }),
        ),
      showEditButton: true,
    },
  ];
  const extraButtons = [
    {
      title: `${intl.formatMessage(commonMessages.add)} ${intl.formatMessage(commonMessages.panel)}`,
      isActive: true,
      onClick: () =>
        dispatch(
          openModal(ANALYZER_SESSION_PANEL_ADD, {
            id: session.id,
            name: session.name,
          }),
        ),
      color: 'green',
      icon: 'fa fa-plus',
    },
    {
      title: intl.formatMessage(commonMessages.print),
      isActive: false,
      icon: 'fa fa-print',
      color: 'secondary',
      onClick: () => {},
    },
    {
      title: intl.formatMessage(commonMessages.archive),
      icon: 'fa fa-archive',
      isActive: true,
      color: 'warning',
      onClick: () =>
        dispatch(
          openModal(ANALYZER_SESSION_COMMAND, {
            id: session.id,
            command: TabCommands.archiveSession,
          }),
        ),
    },
    {
      title: intl.formatMessage(commonMessages.delete),
      icon: 'fa fa-trash',
      isActive: true,
      color: 'red',
      onClick: () =>
        dispatch(
          openModal(ANALYZER_SESSION_COMMAND, {
            id: session.id,
            command: TabCommands.deleteSession,
          }),
        ),
    },
  ];

  return <TabsBar list={mainButtons} extraButtons={extraButtons} disableScroll />;
};

export default injectIntl(SessionActions);
