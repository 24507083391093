import React from 'react';
import { formatNumber } from '../../helper/format';
import { Tooltip } from '../../_tools/tooltip/TooltipV2';

export const NO_VALUE = { value: null, type: 'noValue' } as const;
export const NOT_LOADED = { value: null, type: 'notLoaded' } as const;

type NoValue = typeof NO_VALUE | typeof NOT_LOADED;

interface Value {
  type: 'value';
  value: string | number | boolean;
}

export type CursorValue = NoValue | Value;

interface Props {
  cursorValue: CursorValue;
}

const formatCursorLabel = (value: CursorValue) => {
  switch (value.type) {
    case 'noValue':
      return (
        <Tooltip position='top' data={{ info: 'No value available for this time.' }}>
          <div className='tool-button'>
            <i className='fa fa-info' />
          </div>
        </Tooltip>
      );
    case 'notLoaded':
      return (
        <Tooltip position='top' data={{ info: 'The value is not loaded yet.' }}>
          <div className='tool-button'>
            <i className='fa fa-loading' />
          </div>
        </Tooltip>
      );
    case 'value':
      if (typeof value.value === 'boolean') {
        return value.value ? '1' : '0';
      }
      if (typeof value.value === 'number') {
        return formatNumber(value.value);
      }
      if (value.value === '') {
        const label = '<empty>';
        return <span className={`truncate text-gray-500`}>{label}</span>;
      }
      return <span className={`truncate`}>{value.value}</span>;
  }
};

export const CursorLabel: React.FC<Props> = ({ cursorValue }) => (
  <div className='row-signal-label'>
    <div className='h-full flex items-center pl-1 font-mono text-sm'>
      {formatCursorLabel(cursorValue)}
    </div>
  </div>
);
