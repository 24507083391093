"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { cloneDeep } from "lodash";
import moment from "moment";
import { parseSchema } from "../../../../common/helpers/json.schema";
import { applyCustomOrder } from "../../../../common/helpers/object";
import { flatSchemaToFields } from "./datatron.deviceType";
import { getDatatronStats } from "../api/datatrons";
export const fetchDatatronStats = (datatronId, networkSpeed, nodeIdentifier) => __async(void 0, null, function* () {
  const startTime = moment().startOf("hour").subtract(4, "hours");
  const isArray = (item) => typeof item === "object" && item.length !== void 0;
  const { response } = yield getDatatronStats(datatronId, {
    node: nodeIdentifier,
    networkSpeed: networkSpeed != null ? networkSpeed : 1,
    startTime: startTime.unix(),
    endTime: null,
    step: null
  });
  return {
    stats: {
      total: {
        datatronCpu: !isArray(response == null ? void 0 : response.cpu) ? -1 : response.cpu.slice(-1),
        datatronDisk: !isArray(response == null ? void 0 : response.disk) ? -1 : response.disk.slice(-1),
        datatronNetwork: !isArray(response == null ? void 0 : response.network) ? -1 : response.network.slice(-1),
        datatronRam: !isArray(response == null ? void 0 : response.mem) ? -1 : response.mem.slice(-1)
      },
      chart: [
        {
          startAt: startTime.toISOString(),
          period: "hour",
          datatronCpu: !isArray(response == null ? void 0 : response.cpu) ? -1 : response.cpu,
          datatronDisk: !isArray(response == null ? void 0 : response.disk) ? -1 : response.disk,
          datatronNetwork: !isArray(response == null ? void 0 : response.network) ? -1 : response.network,
          datatronRam: !isArray(response == null ? void 0 : response.mem) ? -1 : response.mem
        }
      ]
    }
  };
});
export const FIRST_FIELDS_ORDER = ["enabled", "uuid", "label", "rate", "isMaxRate", "unit", "scalingFactor", "scalingOffset"];
const getFieldKey = (field) => field._key;
const getConfigFieldKey = (configField) => configField.field._key;
export const applyCustomOrderOnFields = (fields, order = []) => applyCustomOrder(fields, order, getFieldKey);
export const applyCustomOrderOnConfigFields = (fields, order = []) => applyCustomOrder(fields, order, getConfigFieldKey);
export const initializeDatatron = (datatron) => __spreadValues(__spreadValues({}, datatron), fetchDatatronStats(datatron.id, datatron.networkSpeed, { "instance": datatron.ipAddress || "node-exporter:9100" }));
export const initializeDevice = (device) => __spreadProps(__spreadValues({}, device), {
  dataPoints: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    query: "",
    sort: {
      option: null,
      way: null
    }
  },
  archivedDataPoints: {
    list: [],
    loaded: false,
    loading: false,
    error: null,
    query: "",
    sort: {
      option: null,
      way: null
    }
  },
  _update: {
    isRenaming: false,
    loaded: false,
    loading: false,
    error: null
  },
  _archive: {
    loaded: false,
    loading: false,
    error: null
  }
});
export const initializeDeviceType = (deviceType) => __async(void 0, null, function* () {
  const hasDeviceSchema = !!deviceType && !!deviceType.deviceSchema;
  const hasDataPointSchema = !!deviceType && !!deviceType.dataPointSchema;
  const [parsedDeviceSchema, parsedDataPointSchema] = yield Promise.all([
    hasDeviceSchema && parseSchema(deviceType.deviceSchema),
    hasDataPointSchema && parseSchema(deviceType.dataPointSchema)
  ]).catch((err) => [err, err]);
  const result = __spreadProps(__spreadValues({}, cloneDeep(deviceType)), {
    hasDeviceSchema,
    hasDataPointSchema
  });
  if (hasDeviceSchema) {
    result.parsedDeviceSchema = parsedDeviceSchema;
    result.deviceFields = applyCustomOrderOnFields(
      flatSchemaToFields(parsedDeviceSchema),
      FIRST_FIELDS_ORDER
    );
  }
  if (hasDataPointSchema) {
    result.parsedDataPointSchema = parsedDataPointSchema;
    result.dataPointFields = applyCustomOrderOnFields(
      flatSchemaToFields(parsedDataPointSchema),
      FIRST_FIELDS_ORDER
    );
  }
  return result;
});
export const initializeDeviceTypes = (deviceTypes) => __async(void 0, null, function* () {
  return Promise.all(deviceTypes.map(initializeDeviceType));
});
