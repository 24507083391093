import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import App from './components/index/App';
import store from './redux/store';
import onStart from './redux/onStart';
import { initKeycloak, logout } from './auth/keycloakClient';
import { changeLocale } from './redux/modules/locale.module';
import * as Api from './core/api';
import { sessionRestored } from './redux/modules/auth.module';
import { initializeSocketClient } from './socket';
import { isEmpty } from 'lodash';
import { setAppConfig } from './redux/modules/appConfig.module';

require('classlist.js');

const appBody = document.getElementById('react-app');
Modal.setAppElement(appBody);

const accessToken = window.localStorage.getItem('accessToken');
const refreshToken = window.localStorage.getItem('refreshToken');

const init = async () => {
  const success = await initKeycloak(accessToken, refreshToken);
  // TODO: Do we need to do this? the redirect does not work on logout
  if (window.location.pathname === '/logout') {
    return logout({ redirectUri: '/' }); // Otherwise there is a loop, redirecting to /logout)
  }
  if (success) {
    const defaultLocale = store.getState().intl.locale;
    await new Promise((resolve) => {
      store.dispatch(
        changeLocale(defaultLocale, () => {
          resolve();
        }),
      );
    });
    // TODO is this still needed? Can't we use the info from the accessToken? Do we need more? Put that in the token?
    const { response } = await Api.auth.getUser();
    if (response && !isEmpty(response)) {
      const { user, config } = response;
      store.dispatch(sessionRestored(user));
      store.dispatch(setAppConfig(config));
      // Set user locale, don't do so if locale is missing (could happen) or same as default
      if (
        user.attributes?.locale?.[0] !== undefined &&
        user.attributes?.locale?.[0] !== defaultLocale
      ) {
        store.dispatch(changeLocale(user.attributes?.locale[0]));
      }
      initializeSocketClient();
      onStart(store);
      // store.dispatch(dashboardInit.init());

      // Initialization succeeded and the App can be rendered
      ReactDOM.render(
        <React.StrictMode>
          <App store={store} />
        </React.StrictMode>,
        document.getElementById('react-app'),
      );
    } else {
      // User details couldn't be fetched - ask for a re-login. Should restart the init process.
      // keycloak.login();
    }
  } else {
    throw new Error('Failed to initialize keycloak.');
  }
};

init();
