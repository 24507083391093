import React, { useState } from 'react';
import List from './list/List';
import Filters from './filters/DataPointsTableFilters';
import EmptyTable from './emptyList/EmptyTable';
import { Todo, TodoFunction } from '../../../../../../../../common/types/common';
import PermissionedComponent from '../../../../../common/permissions/PermissionedComponent.container';
import { DATATRONS_PERMISSIONS } from '../../../../../../../../common/constants/permissions';
import { useSelector } from 'react-redux';
import {
  getHighlightedDataPointIds,
  getQueryByDeviceId,
} from '../../../../../../redux/selectors/datatron.selector';
import Loader from '../../../../../_tools/loader/Loader';

type Props = {
  dataPoints: Todo[];
  configKeyHashToFieldsMap: Todo;
  deviceId: string;
  deviceType: Todo;
  showArchived: boolean;
  openViewDataPointModal: TodoFunction;
  openEditDataPointModal: TodoFunction;
  openArchiveDataPointModal: TodoFunction;
  openEditArrayIndexModal: TodoFunction;
  openUnarchiveDataPointModal: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  permissions: Todo;
};

export const DataPointsTable: React.FC<Props> = ({
  deviceId,
  configKeyHashToFieldsMap,
  showArchived,
  openViewDataPointModal,
  openEditDataPointModal,
  openArchiveDataPointModal,
  openEditArrayIndexModal,
  openUnarchiveDataPointModal,
  permissions,
  dataPoints,
}) => {
  const highlightedDataPointIds = useSelector(getHighlightedDataPointIds);
  const canUpdate = permissions.canUpdateDatatronDeviceDataPoints();
  const canDelete = permissions.canDeleteDatatronDeviceDataPoints();
  const searchQuery = useSelector((state) => getQueryByDeviceId(state, deviceId, showArchived));
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  return (
    <>
      {dataPoints && (
        <>
          <Filters
            deviceId={deviceId}
            showArchived={showArchived}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            dataPoints={dataPoints}
          />
          {dataPoints?.length === 0 ? (
            <EmptyTable
            // deviceId={deviceId}
            // deviceType={deviceType}
            // readOnly={showArchived}
            />
          ) : (
            <List
              dataPoints={dataPoints}
              configKeyHashToFieldsMap={configKeyHashToFieldsMap}
              readOnly={showArchived}
              highlightedDataPointIds={highlightedDataPointIds}
              openViewDataPointModal={openViewDataPointModal}
              openEditDataPointModal={openEditDataPointModal}
              openArchiveDataPointModal={openArchiveDataPointModal}
              openEditArrayIndexModal={openEditArrayIndexModal}
              openUnarchiveDataPointModal={openUnarchiveDataPointModal}
              canUpdate={canUpdate}
              canDelete={canDelete}
              searchQuery={searchQuery}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
            />
          )}
        </>
      )}
      {!dataPoints && (
        <div>
          <Loader show={true} fullPage={false} />
        </div>
      )}
    </>
  );
};

export default PermissionedComponent(DATATRONS_PERMISSIONS)(DataPointsTable);
