import React from 'react';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import { MenuItem } from '.';
import * as helper from './helper';
import { INTERPOLATION_TYPES, InterpolationType } from '../../../schema/analyzeSessions';

interface Props {
  interpolationType: InterpolationType;
  setInterpolationType: (interpolationType: InterpolationType) => void;
}

const labels: Record<InterpolationType, string> = {
  basis: 'Basis',
  linear: 'Linear',
  monotone: 'Monotone',
  step: 'Step',
  stepAfter: 'Step After',
  stepBefore: 'Step Before',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tooltips: Record<InterpolationType, string> = {
  basis: 'Produces a cubic basis spline using the specified control points.',
  linear: 'Produces a polyline through the specified points.',
  monotone: 'Produces a cubic spline that preserves monotonicity in y, assuming monotonicity in x.',
  step: 'Produces a piecewise constant function (a step function) consisting of alternating horizontal and vertical lines. The y-value changes at the midpoint of each pair of adjacent x-values.',
  stepAfter:
    'Produces a piecewise constant function (a step function) consisting of alternating horizontal and vertical lines. The y-value changes after the x-value.',
  stepBefore:
    'Produces a piecewise constant function (a step function) consisting of alternating horizontal and vertical lines. The y-value changes before the x-value.',
};

export const InterpolationTypeMenu: React.FC<Props> = ({
  interpolationType,
  setInterpolationType,
}) => (
  <RadixContextMenu.Sub>
    <RadixContextMenu.SubTrigger
      className={`${helper.subTriggerClass} flex flex-row justify-between`}
    >
      <div>Change Interpolation</div>
      <i className='fa fa-chevron-right' />
    </RadixContextMenu.SubTrigger>
    <RadixContextMenu.Portal>
      <RadixContextMenu.SubContent sideOffset={2} className={helper.contentClass}>
        {INTERPOLATION_TYPES.map((selectableInterpolationType) => (
          <MenuItem
            key={selectableInterpolationType}
            className={`${helper.itemClass} capitalize gap-1 group`}
            onSelect={() => setInterpolationType(selectableInterpolationType)}
          >
            <div className='flex-grow'>{labels[selectableInterpolationType]}</div>
            {/* <TooltipIcon>
              <div className='max-w-lg'>{tooltips[selectableInterpolationType]}</div>
            </TooltipIcon> */}
            <i
              className={
                interpolationType === selectableInterpolationType
                  ? 'fa  fa-check visible'
                  : 'fa  fa-check  invisible'
              }
            />
          </MenuItem>
        ))}
      </RadixContextMenu.SubContent>
    </RadixContextMenu.Portal>
  </RadixContextMenu.Sub>
);
