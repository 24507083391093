"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import {
  all,
  put,
  call,
  select,
  takeEvery
} from "redux-saga/effects";
import { initializeDevice } from "../../core/common/datatron";
import { getDatatron } from "../selectors/datatron.selector";
import { fetchDataPoints } from "./datatron.devices.dataPoints.fetch.module";
import { getDevices } from "../../core/api/datatrons";
export const fetchDevices = createAction(
  "fetch datatron devices"
);
export const fetchDevicesSuccess = createAction(
  "fetch datatron devices - success",
  (devices) => ({ payload: devices })
);
export const fetchDevicesFail = createAction(
  "fetch datatron devices - fail",
  (error) => ({ payload: error })
);
export const reducer = {
  [fetchDevices.type]: (state) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
        list: [],
        loading: true,
        loaded: false,
        error: null
      })
    })
  }),
  [fetchDevicesSuccess.type]: (state, devices) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
        list: devices,
        loading: false,
        loaded: true,
        error: null
      })
    })
  }),
  [fetchDevicesFail.type]: (state, error) => __spreadProps(__spreadValues({}, state), {
    datatron: __spreadProps(__spreadValues({}, state.datatron), {
      devices: __spreadProps(__spreadValues({}, state.datatron.devices), {
        list: [],
        loading: false,
        loaded: false,
        error
      })
    })
  })
};
export function* fetchDevicesSaga() {
  const state = yield select();
  const datatron = yield call(getDatatron, state);
  const { response, error } = yield call(getDevices, datatron.id);
  if (response) {
    yield put(fetchDevicesSuccess(response.map(initializeDevice)));
    yield all(response.map((item) => put(fetchDataPoints(item.id))));
    yield all(response.map((item) => put(fetchDataPoints(item.id, true))));
  } else {
    yield put(fetchDevicesFail(error));
  }
}
export function* watchFetchDevices() {
  yield takeEvery(fetchDevices, fetchDevicesSaga);
}
