"use strict";
import { defineMessages } from "react-intl";
const messages = defineMessages({
  collectModalTitle: {
    id: "datatron.modals.collect_device.title",
    defaultMessage: "Start the data collection"
  },
  stopModalTitle: {
    id: "datatron.modals.stop_device.title",
    defaultMessage: "Stop the data collection"
  },
  publishModalTitle: {
    id: "datatron.modals.publish_device.title",
    defaultMessage: "Publish the enabled data points"
  },
  submitting: {
    id: "datatron.modals.submitting",
    defaultMessage: "Submitting..."
  },
  info: {
    id: "datatron.modals.info",
    defaultMessage: "Info!"
  },
  success: {
    id: "datatron.modals.success",
    defaultMessage: "Success!"
  },
  error: {
    id: "datatron.modals.error",
    defaultMessage: "Error!"
  },
  changeSetting: {
    id: "datatron.modals.change_setting",
    defaultMessage: "Change setting for "
  },
  status: {
    id: "datatron.modals.status",
    defaultMessage: "Status"
  },
  skipped: {
    id: "datatron.modals.status.skipped",
    defaultMessage: "Skipped"
  },
  completed: {
    id: "datatron.modals.status.completed",
    defaultMessage: "Completed"
  },
  failed: {
    id: "datatron.modals.status.failed",
    defaultMessage: "Failed"
  },
  addPanelTitle: {
    id: "datatron.modals.add_new_panel.title",
    defaultMessage: "Add new panel"
  },
  editPanelTitle: {
    id: "datatron.modals.edit_panel.title",
    defaultMessage: "Edit panel"
  },
  addSessionTitle: {
    id: "datatron.modals.add_new_session.title",
    defaultMessage: "Add New session"
  },
  editSessionTitle: {
    id: "datatron.modals.edit_session.title",
    defaultMessage: "Edit session"
  },
  archiveSessionTitle: {
    id: "datatron.modals.archive_session.title",
    defaultMessage: "Archive session"
  },
  archiveAnomalyTitle: {
    id: "datatron.modals.archive_anomaly.title",
    defaultMessage: "Archive anomaly"
  },
  addNewPanelAnomalyTitle: {
    id: "datatron.modals.add_new_panel_anomaly.title",
    defaultMessage: "Add new panel anomaly"
  },
  deleteSessionTitle: {
    id: "datatron.modals.delete_session.title",
    defaultMessage: "Delete session"
  },
  deleteSessionPanelTitle: {
    id: "datatron.modals.delete_session_panel.title",
    defaultMessage: "Delete session panel"
  },
  deleteSessionPanelDescription: {
    id: "datatron.modals.delete_session_panel.description",
    defaultMessage: "This will delete all data from the panel. Are you sure?"
  },
  deleteAnomalyPanelTitle: {
    id: "datatron.modals.delete_anomaly_panel.title",
    defaultMessage: "Delete anomaly panel"
  },
  deleteAnomalyPanelDescription: {
    id: "datatron.modals.delete_anomaly_panel.description",
    defaultMessage: "This will delete all data from the panel. Are you sure?"
  }
});
export default messages;
