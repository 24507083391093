"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { useEffect, useState } from "react";
import { getSignalData } from "../../../core/api/analyzer";
import { TIME_RANGE_OFFSET_MS } from "../../helper/signalData";
export const useChartData = (signalId, timeRange) => {
  const [state, setState] = useState({
    isLoading: true,
    error: null,
    signalData: null,
    prevRangeFrom: 0,
    prevRangeTo: 0
  });
  useEffect(() => {
    let isSubscribed = true;
    const fetchData = () => __async(void 0, null, function* () {
      if (!signalId || !timeRange)
        return;
      if (Math.abs(timeRange.fromDate.getTime() - state.prevRangeFrom) < TIME_RANGE_OFFSET_MS && Math.abs(timeRange.toDate.getTime() - state.prevRangeTo) < TIME_RANGE_OFFSET_MS) {
        return;
      }
      setState((prev) => __spreadProps(__spreadValues({}, prev), { isLoading: true, error: null }));
      try {
        const response = yield getSignalData(
          1,
          signalId,
          timeRange.fromDate.getTime() - TIME_RANGE_OFFSET_MS,
          timeRange.toDate.getTime() + TIME_RANGE_OFFSET_MS
        );
        if (isSubscribed) {
          setState({
            isLoading: false,
            // @ts-ignore
            signalData: response.status === 200 ? response.data : null,
            // @ts-ignore
            error: response.status !== 200 ? `Error ${JSON.stringify(response.error)}` : null,
            prevRangeFrom: timeRange.fromDate.getTime(),
            prevRangeTo: timeRange.toDate.getTime()
          });
        }
      } catch (error) {
        if (isSubscribed) {
          setState({
            isLoading: false,
            // @ts-ignore
            error: error.message,
            signalData: null,
            prevRangeFrom: 0,
            prevRangeTo: 0
          });
        }
      }
    });
    fetchData();
    return () => {
      isSubscribed = false;
    };
  }, [signalId, state.prevRangeFrom, state.prevRangeTo, timeRange]);
  return state;
};
