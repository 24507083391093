import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import modalMessages from '../../../messages/command.modal.messages';
import BasicModal from '../core/BasicModal';
import Form from './form/SessionForm';
import { closeModal } from '../../../redux/modules/modals.module';
import { createSession } from '../../../redux/modules/analyzer.sessions.create.module';
import { updateSession } from '../../../redux/modules/analyzer.sessions.update.module';
import {
  ANALYZER_SESSION_ADD,
  ANALYZER_SESSION_EDIT,
} from '../../../redux/constants/modals.constants';
import { getModalPayload } from '../../../redux/selectors/modals.selector';
import { AppState } from '../../../redux/state/app.state';

interface SessionFormProps {
  isEditForm: boolean;
}

const modalPayloadSelector = (state: AppState) => getModalPayload(state, ANALYZER_SESSION_EDIT);

const SessionModal: React.FC<SessionFormProps> = ({ isEditForm }) => {
  const dispatch = useDispatch();
  let initialValues = { id: 0, datatron: '', name: '', devices: [] };
  const { formatMessage } = useIntl();
  const session = useSelector(modalPayloadSelector);
  const messageTitle = isEditForm ? modalMessages.editSessionTitle : modalMessages.addSessionTitle;

  if (session) {
    initialValues = {
      id: session.id,
      datatron: session.datatron_uuid,
      name: session.name,
      devices: session.devices,
    };
  }
  const handleSubmit = ({
    datatron,
    name,
    devices,
  }: {
    datatron: string;
    name: string;
    devices: string[];
  }) => {
    if (!isEditForm) {
      dispatch(createSession(datatron, name, devices));
    } else {
      dispatch(updateSession(initialValues.id, name));
    }
  };

  const close = () => {
    if (!isEditForm) {
      dispatch(closeModal(ANALYZER_SESSION_ADD));
    } else {
      dispatch(closeModal(ANALYZER_SESSION_EDIT));
    }
  };

  return (
    <BasicModal close={close} modalClass='modal--sm2'>
      <div className='modal--content'>
        <h3 className='first-title'>{formatMessage(messageTitle)} </h3>
        <Form
          onSubmit={handleSubmit}
          handleCancel={close}
          initialValues={initialValues}
          isEditForm={isEditForm}
        />
      </div>
    </BasicModal>
  );
};

export default SessionModal;
