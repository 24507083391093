"use strict";
import { filter, find, reverse, sortBy, uniq } from "lodash";
import { selector } from "./util";
import { SORT_WAY_DESC } from "../../../../common/constants";
export const getChartType = selector((state) => state.datatron.chartType);
export const getSelectedTab = selector((state) => state.datatron.tab);
export const getDatatron = selector((state) => state.datatron.details);
export const selectIsLoading = selector((state) => !!state.datatron.loading);
export const selectIsLoaded = selector((state) => !!state.datatron.loaded);
export const getAssistants = selector((state) => getDatatron(state).assistants);
export const getDeviceTypes = selector((state) => state.datatron.deviceTypes.list);
export const getDeviceTypeById = selector((state, deviceTypeId) => {
  if (!deviceTypeId)
    return null;
  const list = getDeviceTypes(state);
  return list.find((item) => item.id === deviceTypeId);
});
export const getDeviceTypesByClass = (state, deviceClass) => filter(getDeviceTypes(state), { deviceClass });
export const getDeviceClasses = selector((state) => state.datatron.deviceClasses.list);
export const getDeviceClassesWithDeviceTypes = (state) => {
  const deviceTypes = getDeviceTypes(state);
  return uniq(deviceTypes.map((item) => item.deviceClass));
};
export const getDevices = selector((state) => state.datatron.devices.list);
export const areDevicesLoaded = selector((state) => state.datatron.devices.loaded);
export const getDeviceById = selector(
  (state, id) => find(state.datatron.devices.list, { id })
);
export const getNewDataPointFormDeviceId = selector(
  (state) => state.datatron.newDataPoint.deviceId
);
export const getArchivedDataPointsOfDevice = (device) => device.archivedDataPoints.list;
export const getDataPointsOfDevice = (device) => device.dataPoints.list;
export const getFilterByDeviceId = (field, state, deviceId, archived = false) => {
  if (!deviceId) {
    return null;
  }
  const device = getDeviceById(state, deviceId);
  if (!device) {
    return null;
  }
  return !archived ? device.dataPoints[field] : device.archivedDataPoints[field];
};
export const getQueryByDeviceId = (state, deviceId, archived = false) => getFilterByDeviceId("query", state, deviceId, archived);
export const getFieldFiltersByDeviceId = (state, deviceId, archived = false) => getFilterByDeviceId("filters", state, deviceId, archived);
export const getSortByDeviceId = (state, deviceId, archived = false) => {
  if (!deviceId) {
    return null;
  }
  const device = getDeviceById(state, deviceId);
  if (!device) {
    return null;
  }
  return !archived ? device.dataPoints.sort : device.archivedDataPoints.sort;
};
export const getDataPointFromListById = (dataPoints, id) => find(dataPoints, { id });
export const getDataPointsByDeviceId = (state, deviceId, archived) => {
  const device = getDeviceById(state, deviceId);
  if (!device) {
    return [];
  }
  let list = !archived ? getDataPointsOfDevice(device) : getArchivedDataPointsOfDevice(device);
  const query = getQueryByDeviceId(state, deviceId, archived);
  const filters = getFieldFiltersByDeviceId(state, deviceId, archived);
  if (query) {
    const lcQuery = query.toLowerCase();
    list = list.filter(
      (item) => {
        var _a;
        return item.uuid.toLowerCase().indexOf(lcQuery) >= 0 || item.label.toLowerCase().indexOf(lcQuery) >= 0 || (item.config.identifier || "").toLowerCase().indexOf(lcQuery) >= 0 || (((_a = item.config.dataType) == null ? void 0 : _a.name) || "").toLowerCase().indexOf(lcQuery) >= 0;
      }
      // Handling dataType.name
    );
  }
  if (filters) {
    const fields = Object.keys(filters);
    if (fields.length > 0) {
      list = list.filter(
        (item) => fields.every(
          (field) => filters[field].length === 0 || filters[field].some((i) => i.toString() === item[field].toString())
        )
      );
    }
  }
  const currentSort = getSortByDeviceId(state, deviceId, archived);
  if (!currentSort.option) {
    return list;
  }
  const sortedList = sortBy(list, (item) => item[currentSort.option]);
  if (currentSort.way === SORT_WAY_DESC) {
    return reverse(sortedList);
  }
  return sortedList;
};
export const getDataPointByIdAndDeviceId = (state, deviceId, dataPointId, archived = false) => {
  if (!deviceId || !dataPointId) {
    return null;
  }
  const device = getDeviceById(state, deviceId);
  if (!device) {
    return null;
  }
  const dataPoints = !archived ? getDataPointsOfDevice(device) : getArchivedDataPointsOfDevice(device);
  return getDataPointFromListById(dataPoints, dataPointId);
};
export const getHighlightedDataPointIds = (state) => state.datatron.highlightedDataPointIds || [];
export const selectDatatronError = (state) => state.datatron.newDataPoint.error || {};
