"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { AppReducerMapBuilder } from "./util";
import { transformErrorToMessageString } from "../../../../common/utils";
import { addNewPanel } from "../../core/api/analyzer";
import * as norm from "../../schema/normalized";
import { succeeded } from "../../schema/fetched";
import { startSubmit, stopSubmit } from "redux-form";
import { ANALYZER_SESSION_PANEL_FORM } from "../constants/analyzer.form.constants";
import { ANALYZER_SESSION_PANEL_ADD } from "../constants/modals.constants";
import { closeModal } from "./modals.module";
import { sendNotification } from "./notifications.module";
import notificationMessages from "../../messages/notification.message";
import * as notification from "../../core/notifications";
import { getPanelById, getSignalsByPanelId } from "../selectors/analyzer.selector";
import { INITIAL_SETTINGS, INITIAL_TOOL } from "../../schema/type/panelTool";
import { INITIAL_RANGE_FROM, MIN_RANGE_DELTA_MS } from "../../schema/analyzeSessions";
import { calculatePanelTime } from "../../components/helper/panelTime";
export function transformSignal(signal) {
  const { color, chart_type, interpolation_type, order, height, signal_id } = signal;
  return {
    color,
    chart_type,
    interpolation_type,
    order,
    height,
    signal_id
  };
}
export const createSessionPanel = createAction(
  "create session panel",
  (sessionId, name, panelId) => ({
    payload: { sessionId, panelId, name }
  })
);
export const createSessionPanelSuccess = createAction("create session panel success", (panel) => ({
  payload: panel
}));
export const reducer = AppReducerMapBuilder.new().add(createSessionPanelSuccess, (state, panel) => {
  const _a = panel, { panel_signals: panelSignals } = _a, otherPanelAttributes = __objRest(_a, ["panel_signals"]);
  let normalizedPanelSignals = state.analyzer.panelSignals;
  const settingsInit = INITIAL_SETTINGS;
  const toolInit = INITIAL_TOOL;
  const rangeFrom = panel.range_from ? new Date(panel.range_from) : INITIAL_RANGE_FROM;
  const rangeDeltaMs = panel.range_delta_ms ? panel.range_delta_ms : MIN_RANGE_DELTA_MS;
  const timeInit = calculatePanelTime(rangeFrom, rangeDeltaMs);
  panelSignals.forEach((signal) => {
    normalizedPanelSignals = norm.upsert(
      normalizedPanelSignals,
      signal.id,
      succeeded(__spreadProps(__spreadValues({}, signal), { addons: [] }))
    );
  });
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: __spreadValues({}, norm.upsert(
        state.analyzer.panels,
        panel.id,
        succeeded(__spreadProps(__spreadValues({}, otherPanelAttributes), {
          settings: settingsInit,
          tool: toolInit,
          time: timeInit
        }))
      )),
      panelSignals: normalizedPanelSignals
    })
  });
}).build();
export function* createSessionPanelSaga({ payload: { sessionId, panelId, name } }) {
  if (panelId) {
    const state = yield select();
    const panelData = yield call(getPanelById, state, panelId);
    const signals = yield call(getSignalsByPanelId, state, panelId);
    const transformedSignals = signals.map(transformSignal);
    const data = {
      name: `${name} (COPY)`,
      machine_id: panelData.machine_id,
      range_delta_ms: panelData.range_delta_ms,
      range_from: panelData.range_from,
      signals: transformedSignals
    };
    const { response, error } = yield call(addNewPanel, sessionId, data);
    if (response) {
      yield put(createSessionPanelSuccess(response));
      yield put(
        sendNotification(
          notificationMessages.successfully_changed_event,
          notificationMessages.successfully_changed_event_description,
          notification.NOTIFICATION_EVENT
        )
      );
    } else {
      const errorString = transformErrorToMessageString(error);
      yield put(
        sendNotification(
          __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
            values: { error: errorString }
          }),
          null,
          notification.NOTIFICATION_ERROR
        )
      );
    }
  } else {
    yield put(startSubmit(ANALYZER_SESSION_PANEL_FORM));
    const { response, error } = yield call(addNewPanel, sessionId, { name });
    yield put(stopSubmit(ANALYZER_SESSION_PANEL_FORM));
    yield put(closeModal(ANALYZER_SESSION_PANEL_ADD));
    if (response) {
      yield put(createSessionPanelSuccess(response));
      yield put(
        sendNotification(
          notificationMessages.successfully_changed_event,
          notificationMessages.successfully_changed_event_description,
          notification.NOTIFICATION_EVENT
        )
      );
    } else {
      const errorString = transformErrorToMessageString(error);
      yield put(
        sendNotification(
          __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
            values: { error: errorString }
          }),
          null,
          notification.NOTIFICATION_ERROR
        )
      );
    }
  }
}
export function* watchCreateSessionPanel() {
  yield takeEvery(createSessionPanel, createSessionPanelSaga);
}
