import React from 'react';

interface ExtraButtonsItemProps {
  title: string;
  onClick: () => void;
  color?: 'green' | 'primary' | 'warning' | 'danger';
  icon?: string;
  isActive?: boolean;
}

export const ExtraButtonsItem: React.FC<ExtraButtonsItemProps> = ({
  title,
  onClick,
  color = 'green',
  icon = 'fa fa-plus',
  isActive = true,
}) => (
  <button className={`button extra-button ${color} mr-1`} onClick={onClick} disabled={!isActive}>
    <span className={icon} />
    {title}
  </button>
);

export default ExtraButtonsItem;
