import React from 'react';
import { SignalStateDisplay } from './SignalStateDisplay';

/**
 * Displays an error message.
 *
 * @returns {ReactElement} The JSX Element
 */
export const ErrorBox: React.FC<{ error?: string }> = () => (
  <SignalStateDisplay
    type='error'
    icon='fa fa-frown-o'
    messageId='validation.file.error.default_message'
    className='custom-class'
  />
);
