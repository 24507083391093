import React from 'react';
import { getChartStylesContainer } from './Helper';
import { LoadingChart } from './LoadingChart';
import { EmptyChart } from './EmptyChart';
import { ErrorBox } from './ErrorBox';
import { CustomAreaChart } from './CustomAreaChart';
import { CustomLineChart } from './CustomAreaLineChart';
import { isStringSignalData, SignalData } from '../../../schema/signalData';
import { CustomScatterChart } from './CustomScatterChart';

interface SignalChartProps {
  panelId: number;
  panelSignalProps: {
    type: 'integer' | 'double' | 'bool';
    chart_type: 'line' | 'area';
    color: string;
    interpolation_type: 'linear' | 'step';
    id: number;
    height: number;
  };
  signalData: SignalData | null;
  fromUnixNano: number;
  toUnixNano: number;
  isLoading: boolean;
  error: string | null;
}

export const SignalChart: React.FC<SignalChartProps> = ({
  panelId,
  panelSignalProps,
  signalData,
  fromUnixNano,
  toUnixNano,
  isLoading,
  error,
}) => (
  <div className='row-signal-chart'>
    <div style={getChartStylesContainer(panelSignalProps.height)}>
      {isLoading ? (
        <LoadingChart />
      ) : error || signalData === null ? (
        <ErrorBox />
      ) : signalData.data.length === 0 ? (
        <EmptyChart />
      ) : isStringSignalData(signalData) ? (
        <CustomScatterChart
          panelId={panelId}
          data={signalData.data}
          mainSignalAttr={panelSignalProps}
          fromUnixNano={fromUnixNano}
          toUnixNano={toUnixNano}
        />
      ) : panelSignalProps.chart_type === 'line' ? (
        <CustomLineChart
          panelId={panelId}
          data={signalData.data}
          mainSignalAttr={panelSignalProps}
          fromUnixNano={fromUnixNano}
          toUnixNano={toUnixNano}
        />
      ) : panelSignalProps.chart_type === 'area' ? (
        <CustomAreaChart
          panelId={panelId}
          data={signalData.data}
          mainSignalAttr={panelSignalProps}
          fromUnixNano={fromUnixNano}
          toUnixNano={toUnixNano}
        />
      ) : (
        <EmptyChart />
      )}
    </div>
  </div>
);
