import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import ExtraButtons from '../../common/tabsBar/extraButtons/ExtraButtons';
import { closeModal, openModal } from '../../../redux/modules/modals.module';
import {
  ANALYZER_SESSION_PANEL_EDIT,
  CONFIRMATION,
} from '../../../redux/constants/modals.constants';
import modalMessages from '../../../messages/command.modal.messages';
import { deleteSessionPanel } from '../../../redux/modules/analyzer.sessions.panel.delete.module';
import { createSessionPanel } from '../../../redux/modules/analyzer.sessions.panel.create.module';
import {
  updateSessionPanel,
  cloneAnomalyPanel,
  deleteAnomalyPanel,
} from '../../../redux/modules/analyzer.sessions.panel.update.module';

interface PanelActionsProps {
  status: string;
  panel: {
    name: string;
    id: number;
    session_id: number;
    isUpdated?: boolean;
    isPanelAnomaly?: boolean;
  };
}
export const PanelActions: React.FC<PanelActionsProps> = ({ status, panel }) => {
  const dispatch = useDispatch();

  const extraButtons = useMemo(
    () => [
      {
        isActive: true,
        onClick: () =>
          dispatch(
            openModal(ANALYZER_SESSION_PANEL_EDIT, {
              sessionId: panel.session_id,
              panelId: panel.id,
              name: panel.name,
            }),
          ),
        color: 'light-grey-white',
        icon: 'fa fa-pencil',
      },
      {
        icon: 'fa fa-floppy-o',
        isActive: true,
        color: status === 'updated' ? 'light-green-white' : 'light-grey-white',
        onClick: () => dispatch(updateSessionPanel(panel.session_id, panel.id, {})),
      },
      {
        icon: 'fa fa-clone ',
        isActive: true,
        color: 'light-grey-white',
        onClick: () => dispatch(createSessionPanel(panel.session_id, panel.name, panel.id)),
      },
      {
        icon: 'fa fa-trash',
        isActive: true,
        color: 'light-grey-white',

        onClick: () =>
          dispatch(
            openModal(CONFIRMATION, {
              title: modalMessages.deleteSessionPanelTitle,
              description: modalMessages.deleteSessionPanelDescription,
              done: () => {
                dispatch(closeModal(CONFIRMATION));
                dispatch(deleteSessionPanel(panel.session_id, panel.id));
              },
            }),
          ),
      },
    ],
    [dispatch, status, panel],
  );

  const anomalyExtraButtons = useMemo(
    () => [
      {
        icon: 'fa fa-clone ',
        isActive: true,
        color: 'light-grey-white',
        onClick: () => dispatch(cloneAnomalyPanel(panel.id)),
      },
      {
        icon: 'fa fa-trash',
        isActive: true,
        color: 'light-grey-white',
        onClick: () =>
          dispatch(
            openModal(CONFIRMATION, {
              title: modalMessages.deleteAnomalyPanelTitle,
              description: modalMessages.deleteAnomalyPanelDescription,
              done: () => {
                dispatch(closeModal(CONFIRMATION));
                dispatch(deleteAnomalyPanel(panel.id));
              },
            }),
          ),
      },
    ],
    [dispatch, panel],
  );

  const panelButtons = panel.isPanelAnomaly ? anomalyExtraButtons : extraButtons;

  return <ExtraButtons list={panelButtons} />;
};
