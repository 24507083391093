import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { CustomTimeInput } from './CustomTimeInput';

interface DatePickerComponentProps {
  selectedDate: Date;
  onDateChange?: (date: Date) => void;
  className?: string;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  timeInputLabel?: string;
  placeholderText?: string;
}

/**
 * DateTimePickerComponent is a React component that wraps the `react-datepicker` component
 * to provide a more convenient API for selecting dates and times.
 *
 * @param initialDate - The initial date to be selected. Defaults to the current date.
 * @param onDateChange - A callback function that is called whenever the selected date changes.
 * @param className - A CSS class name to be applied to the `DatePicker` component.
 * @param disabled - A boolean indicating whether the date picker should be disabled.
 * @param minDate - The minimum date that can be selected.
 * @param maxDate - The maximum date that can be selected.
 */
export const DateTimePickerComponent: React.FC<DatePickerComponentProps> = ({
  selectedDate,
  onDateChange,
  className,
  disabled = false,
  minDate,
  maxDate,
  timeInputLabel = 'Time:',
  placeholderText = 'Select date and time',
}) => {
  const handleDateChange = useCallback(
    (date: Date) => {
      onDateChange?.(date);
    },
    [onDateChange],
  );

  const handleTimeChange = useCallback(
    (date: Date, timeString: string) => {
      const [hours, minutes, seconds] = timeString.split(':').map(Number);
      const newDate = new Date(date);

      newDate.setHours(hours || 0);
      newDate.setMinutes(minutes || 0);
      newDate.setSeconds(seconds || 0);

      handleDateChange(newDate);
    },
    [handleDateChange],
  );

  return (
    <DatePicker
      selected={selectedDate}
      onChange={handleDateChange}
      timeInputLabel={timeInputLabel}
      dateFormat='dd/MM/yyyy HH:mm:ss'
      showTimeInput
      customTimeInput={<CustomTimeInput date={selectedDate} onChangeCustom={handleTimeChange} />}
      className={`datepicker-input ${className || ''}`}
      placeholderText={placeholderText}
      disabled={disabled}
      minDate={minDate}
      maxDate={maxDate}
      timeFormat='HH:mm:ss'
      timeIntervals={1}
    />
  );
};
