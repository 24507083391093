"use strict";
export const unixNanoToDate = (unixNano) => new Date(unixNano / 1e6);
export const dateToUnixNano = (date) => date.getTime() * 1e6;
export const formatUnixNano = (unixNano) => {
  const date = unixNanoToDate(unixNano);
  return date.toISOString();
};
export const formatLocalDate = (date) => date.toLocaleString("de-DE", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  second: "numeric"
  // fractionalSecondDigits: showMs ? 3 : undefined,
});
export const formatLocalTime = (date, showMs = true) => date.toLocaleTimeString("de-DE", {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
  // @ts-ignore TODO: fix this
  fractionalSecondDigits: showMs ? 3 : void 0
});
const durationString = (us, s = 0, m = 0, h = 0) => {
  const usString = us.toString().padStart(6, "0");
  const sString = s.toString().padStart(2, "0");
  const mString = m.toString().padStart(2, "0");
  const hString = h.toString().padStart(2, "0");
  return `${hString}:${mString}:${sString}.${usString}`;
};
export const formatDuration = (durationNano) => {
  const durationMicros = Math.round(durationNano / 1e3);
  if (durationMicros === 0)
    return "";
  const us = durationMicros % 1e6;
  let rest = Math.floor(durationMicros / 1e6);
  if (rest === 0)
    return durationString(us);
  const s = rest % 60;
  rest = Math.floor(rest / 60);
  if (rest === 0)
    return durationString(us, s);
  const m = rest % 60;
  rest = Math.floor(rest / 60);
  return durationString(us, s, m, rest);
};
export const addMsDuration = (date, durationMs) => new Date(date.getTime() + durationMs);
export const addNanoDuration = (date, durationNano) => addMsDuration(date, durationNano / 1e6);
export const formatDateWithMicrosecondsAndOffset = (date) => {
  const pad = (n, width = 2) => String(n).padStart(width, "0");
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());
  const milliseconds = pad(date.getMilliseconds(), 3) + "000";
  const tzOffset = -date.getTimezoneOffset();
  const tzSign = tzOffset >= 0 ? "+" : "-";
  const tzHours = pad(Math.floor(Math.abs(tzOffset) / 60));
  const tzMinutes = pad(Math.abs(tzOffset) % 60);
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${tzSign}${tzHours}:${tzMinutes}`;
};
