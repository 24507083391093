import React from 'react';
import { TooltipProps } from 'recharts';

export const ChartTooltip: React.FC<TooltipProps<number, number>> = ({ active, payload }) => {
  if (!active || !payload?.length) return null;

  const data = payload[0].payload;
  const localTime = new Date(data.systime / 1e6).toLocaleString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  const isoTime = new Date(data.systime / 1e6).toISOString();

  return (
    <>
      <div className='chart-tooltip'>
        <div className='tooltip-grid'>
          <div className='tooltip-label'>System Time (ISO):</div>
          <div>{isoTime}</div>

          <div className='tooltip-label'>System Time (Local):</div>
          <div>{localTime}</div>

          <div className='tooltip-label'>Is Delta:</div>
          <div>{data.isDelta ? 'yes' : 'no'}</div>

          <div className='tooltip-label'>Value (boolean):</div>
          <div>{payload[0].value}</div>
        </div>
      </div>
    </>
  );
};
