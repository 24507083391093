import React from 'react';

interface CustomTimeInputProps {
  date: Date | undefined;
  onChangeCustom: (date: Date, timeString: string) => void;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * A custom time input component.
 *
 * @typedef {object} CustomTimeInputProps
 * @property {Date | undefined} date - The date to display the time for.
 * @property {(date: Date, timeString: string) => void} onChangeCustom - A callback to call when the time is changed.
 * @property {string} [value] - The value of the input field.
 * @property {(event: React.ChangeEvent<HTMLInputElement>) => void} [onChange] - A callback to call when the input field is changed.
 */
export const CustomTimeInput: React.FC<CustomTimeInputProps> = React.forwardRef(
  ({ date, onChangeCustom }, ref: React.Ref<HTMLInputElement>) => {
    const formatTime = (dateAttr: Date | undefined): string => {
      if (!dateAttr) return '';
      return dateAttr.toLocaleTimeString('de-DE', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
    };

    const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (date) {
        onChangeCustom(date, event.target.value);
      }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    return (
      <input
        ref={ref}
        type='time'
        step='1'
        value={formatTime(date)}
        onChange={handleTimeChange}
        className='custom-time-input'
        onKeyDown={handleKeyDown}
      />
    );
  },
);
