"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import { AppReducerMapBuilder } from "./util";
import { createAction } from "@reduxjs/toolkit";
import { getAnomalyById, getSignals } from "../../core/api/analyzer";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { LOADING, succeeded, failed, IDLE } from "../../schema/fetched";
import * as norm from "../../schema/normalized";
import { createPanelSignal, createDefaultAnomalyPanels } from "../../schema/analyzeSessions";
import { fetchAnalyzerSessionSignalsSuccess } from "./analyzer.session.fetch.module";
export const fetchAnalyzerAnomaly = createAction("fetch anomaly", (anomalyId) => ({
  payload: anomalyId
}));
export const fetchAnalyzerAnomalySuccess = createAction(
  "fetch anomaly - success",
  (id, anomaly) => ({
    payload: { id, anomaly }
  })
);
export const fetchAnalyzerAnomalyFailure = createAction("fetch anomaly - fail", (error) => ({
  payload: error
}));
export const resetAnalyzerAnomaly = createAction("reset analyzer anomaly");
export const reducer = AppReducerMapBuilder.new().add(fetchAnalyzerAnomaly, (state, anomalyId) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    anomalies: norm.upsert(state.analyzer.anomalies, anomalyId, LOADING)
  })
})).add(fetchAnalyzerAnomalySuccess, (state, { id, anomaly }) => {
  const _a = anomaly, { signals } = _a, otherAnomalyAttributes = __objRest(_a, ["signals"]);
  let normalizedPanels = norm.initial();
  let normalizedPanelSignals = norm.initial();
  const panels = createDefaultAnomalyPanels(otherAnomalyAttributes);
  panels.map((panel) => {
    normalizedPanels = norm.upsert(normalizedPanels, panel.id, succeeded(panel));
    let order = 0;
    signals.map((signal) => {
      const insertedSignal = createPanelSignal(panel.id, order++, signal);
      normalizedPanelSignals = norm.upsert(
        normalizedPanelSignals,
        insertedSignal.id,
        succeeded(insertedSignal)
      );
    });
  });
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      anomalies: norm.upsert(
        state.analyzer.anomalies,
        id,
        succeeded(__spreadValues({}, otherAnomalyAttributes))
      ),
      panels: normalizedPanels,
      panelSignals: normalizedPanelSignals
    })
  });
}).add(fetchAnalyzerAnomalyFailure, (state, { id, error }) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    anomalies: norm.upsert(state.analyzer.anomalies, id, failed(error))
  })
})).add(resetAnalyzerAnomaly, (state) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    panels: norm.initial(),
    panelSignals: norm.initial(),
    signals: IDLE
  })
})).build();
function* fetchAnalyzerAnomalySaga({ payload: anomalyId }) {
  try {
    const [response, signalResponse] = yield all([call(getAnomalyById, anomalyId), call(getSignals)]);
    if (response.type === "generalError") {
      yield put(fetchAnalyzerAnomalyFailure({ id: anomalyId, error: response.error }));
      return;
    }
    if (response.type === "parseError") {
      yield put(fetchAnalyzerAnomalyFailure({ id: anomalyId, error: response.error.message }));
      return;
    }
    if (signalResponse.status == 200 && signalResponse.data) {
      yield put(fetchAnalyzerSessionSignalsSuccess(signalResponse.data));
    }
    if (response.status == 200 && response.data) {
      yield put(fetchAnalyzerAnomalySuccess(anomalyId, response.data));
    }
  } catch (Err) {
    yield put(fetchAnalyzerAnomalyFailure({ id: anomalyId, error: Err }));
  }
}
export function* watchFetchAnalyzerAnomaly() {
  yield takeLatest(fetchAnalyzerAnomaly, fetchAnalyzerAnomalySaga);
}
